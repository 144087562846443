import React, { useState } from "react";
import "../css/UploadPhotos.css";
import { ThemeTextField, ThemeButton } from "../muiTheme/Theme";
import "../css/addProductForm.css";

const TechnicalConfirmationModel = ({ open, handleClose, text, buttontext, onSendClick }) => {

  return (
    <div>
      <div className="mainDiv">
        <div className="sendphotomodal !mt-0 !m-0 py-6 px-5 w-full">
          <div className="py-5">
            <p className="AddProductErrorDownloadPhotoLabel  pb-[16px] ">
              {text}
            </p>
          </div>
          <div className="flex justify-between">
            <ThemeButton
              id="btn2"
              variant="contained"
              onClick={handleClose}
              style={{ width: "116px", marginLeft: "66px" }}
            >
              Cancel
            </ThemeButton>
            <ThemeButton
              id="btn1"
              variant="contained"
              onClick={onSendClick}

              style={{ width: "116px" }}
            >
              {buttontext}
            </ThemeButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicalConfirmationModel;
