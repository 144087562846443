import { createSlice } from '@reduxjs/toolkit';

const LinkConfirmationSlice = createSlice({
 name: 'linkconfirmationslice',
 initialState: {
  linkPopUpOpen: false, // Set an initial value for the `downloaded` property
 },
 reducers: {
    setLinkPopUpOpen: (state, action) => {
   state.linkPopUpOpen = action.payload; // Update the `downloaded` property in the state
  },
 },
});

export const { setLinkPopUpOpen } = LinkConfirmationSlice.actions;
export default LinkConfirmationSlice.reducer;
