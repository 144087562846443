import img from "../image/cardImg.png";

 const data = [
  {
    title: "42” Smart TV",
    subTitle: "Y0139836",
    image: img,
    type: 'review'
  },
  {
    title: "42” Smart TV",
    subTitle: "Y0139836",
    image: img,
    type: 'error'
  },
 
  {
    title: "42” Smart TV",
    subTitle: "Y0139836",
    image: img,
    type: 'download'
  },
  {
    title: "42” Smart TV",
    subTitle: "Y0139836",
    image: img,
    type: 'done'
  },
  {
    title: "42” Smart TV",
    subTitle: "Y0139836",
    image: img,
    type: 'process'
  },
  {
    title: "42” Smart TV",
    subTitle: "Y0139836",
    image: img,
    type: 'download'
  },
  {
    title: "42” Smart TV",
    subTitle: "Y0139836",
    image: img,
    type: 'download'
  },
  {
    title: "42” Smart TV",
    subTitle: "Y0139836",
    image: img,
    type: 'download'
  },
  {
    title: "42” Smart TV",
    subTitle: "Y0139836",
    image: img,
    type: 'download'
  },
  {
    title: "42” Smart TV",
    subTitle: "Y0139836",
    image: img,
    type: 'download'
  },
  {
    title: "42” Smart TV",
    subTitle: "Y0139836",
    image: img,
    type: 'download'
  },
  {
    title: "42” Smart TV",
    subTitle: "Y0139836",
    image: img,
    type: 'download'
  },
  {
    title: "42” Smart TV",
    subTitle: "Y0139836",
    image: img,
    type: 'download'
  },
];


export default data