import { createSlice } from '@reduxjs/toolkit';

const onSendClickConfirmationSlice = createSlice({
 name: 'parent',
 initialState: {
  downloaded: false, // Set an initial value for the `downloaded` property
 },
 reducers: {
  setDownloaded: (state, action) => {
   state.downloaded = action.payload; // Update the `downloaded` property in the state
  },
 },
});

export const { setDownloaded } = onSendClickConfirmationSlice.actions;
export default onSendClickConfirmationSlice.reducer;
