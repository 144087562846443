import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL/BaseUrl";
const axios = require("axios");

export const getDoneTasks = createAsyncThunk(
  "tasks/getDoneTasks",
  async (userId) => {
    try {
      const res = await axios.post(`${BaseUrl}/api/admin/getdonetasks`, { userId });
      return res.data.companyData;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getDoneTasksSlice = createSlice({
  name: "doneTasks",
  initialState: {
    tasks: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getDoneTasks.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getDoneTasks.fulfilled]: (state, action) => {
      state.loading = false;
      state.tasks = action.payload;
    },
    [getDoneTasks.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export const selectLoadingDoneTasks = (state) => state.doneTasks.loading;

export default getDoneTasksSlice.reducer;
