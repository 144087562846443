import React, { useState } from "react";

import { ReactComponent as DownloadPhoto } from "../image/svg/AddProductErrorDialogIcon.svg";
import p1 from "../image/cardImg.png";
import p2 from "../image/DownloadPhotoArrow.png";

import "../css/addProductForm.css";
import "../css/AddProductDialogBox.css";
import DownloadPhotoDialogCompo from "./DownloadPhotoDialogCompo";
import { useDispatch, useSelector } from "react-redux";
import {
  ThemeAddProductErrorDialogDownloadButton,
  ThemeAddProductErrorDialogProcessButton,
  ThemeConfirmationError,
  ThemeUploadConfirmationError,
} from "../muiTheme/AddProductErrordialog";
import { inputFocus, changeInput } from "../muiTheme/OnFilledBorderStyle";


import { ReactComponent as PdfIcon } from "../image/svg/AddProductErrorDialogIcon.svg";
import { ReactComponent as DownloadIcon } from "../image/svg/DownloadIcon.svg";
import { BaseUrl } from "../BaseURL/BaseUrl";
import { updateProduct } from "../redux/Slices/UpdateProductSlice";
import { getAllProduct } from "../redux/Slices/GetAllProductSlice";


const UploadConformationDialogBox = ({ setuploadConfirmation }) => {
  const [array, setarray] = useState(["1"]);
  const [firstText, setfirstText] = useState();
  const [secondText, setsecondText] = useState();
  const [thirdText, setthirdText] = useState();
  const [firstError, setFirstError] = useState(false)
  const [secondError, setSecondError] = useState(false)
  const [thirdError, setThirdError] = useState(false)
  const [url1Error, setUrl1Error] = useState(false)
  const [url2Error, setUrl2Error] = useState(false)
  const [url3Error, setUrl3Error] = useState(false)
  const errorData = useSelector((state) => state.addAdminPhoto);


  const dispatch = useDispatch();
  var newData = [];
  if (errorData.dataError.length > 0) {
    newData = errorData.dataError[0].photos
    console.log(errorData, "download Datayyyy on Upload");
  }
  const newLinkClick = () => {
    // array.push('1')
    setarray([...array, "2"]);
  };
  const downloadPhoto = (data) => {
    console.log("DATAAAA", data)
    // const url = document.getElementById("certificatePhoto").getAttribute("src"); 
    const url = `${BaseUrl}/public/productImages/${data}`;
    const a = document.createElement("a");
    a.href = url;
    a.download = "Certificate.png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const firstChange = (e) => {
    setfirstText(e.target.value);
  };

  const secondChange = (e) => {
    setsecondText(e.target.value);
  };

  const thirdChange = (e) => {
    setthirdText(e.target.value);

  };


  let url = [];

  const proceedClick = () => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // IP address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

    const isUrlValid1 = urlPattern.test(firstText);
    const isUrlValid2 = urlPattern.test(secondText);
    const isUrlValid3 = urlPattern.test(thirdText);

    if (isUrlValid1) {
      setUrl1Error(false)
    } else {
      setUrl1Error(true)
    }
    if (isUrlValid2) {
      setUrl2Error(false)
    } else {
      setUrl2Error(true)
    }
    if (isUrlValid3) {
      setUrl3Error(false)
    } else {
      setUrl3Error(true)
    }



    if (firstText) {
      setFirstError(false)
    } else {
      setFirstError(true)
    }


    if (firstText && isUrlValid1) {
      url.push({ url: firstText, status: "pending" });
    }
    if (secondText && isUrlValid2) {
      url.push({ url: secondText, status: "pending" });
    }
    if (thirdText && isUrlValid3) {
      url.push({ url: thirdText, status: "pending" });
    }

    console.log(url, "Urll");
    const clearForm = () => {
      setuploadConfirmation(false);
      setfirstText('')
      setsecondText('')
      setthirdText('')
      setUrl1Error(false);
      setUrl2Error(false);
      setUrl3Error(false);
    }

    if ((firstText && isUrlValid1) || (secondText && isUrlValid2) || (thirdText && isUrlValid3)) {
      dispatch(updateProduct({ url: url, id: errorData.dataError[0].id }))
      .unwrap()
      .then((res)=> {
        if(res?.success){
          dispatch(getAllProduct());
          clearForm()
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }


  };
  return (
    <div>
      <div className="flex flex-col justify-start  gap-[16px] p-[20px]">
        <div>
          <p className="AddProductErrorDownloadPhotoLabel  pb-[16px]">
            You can still dowload the photos{" "}
          </p>
          {newData?.map((item, index) => (
            <div className="flex justify-between">
              <div className="flex gap-[8px] items-center">
                <p>
                  <PdfIcon />
                </p>
                <p className="addProductErrorFileName">{item == index[0] ? item : item}</p>
                <p className="pdfSize">1.8 mb</p>
              </div>
              <p>
                <DownloadIcon
                  onClick={() => downloadPhoto(item == index[0] ? item : item)}
                  className=" cursor-pointer  hover:bg-[#F5F5F5]"
                />
              </p>
            </div>
          ))}
        </div>
        <div className=" ">
          <p className=" downloadPhotosText !text-start">
            Share the link of the product page, you uploaded photos to (up to 3
            links)
          </p>
        </div>
        <div>
          <p className="AddProducturlLabel">URL of your product</p>
          <ThemeUploadConfirmationError
            autoFocus={"true"}
            // size="small"
            fullWidth
            value={firstText}
            onChange={(e) => firstChange(e)}
            // defaultValue={''}
            name="url1"
            placeholder="URL"
            // {...register("email")}
            onFocus={(event) => inputFocus(event)}
            onBlur={(event) => changeInput(event)}
            firstError={firstError}
          />
          {firstError == true ? (
            <p className="error_messge">Please Add a URL</p>
          ) : url1Error == true ? (
            <p className="error_messge">URL is not Valid</p>
          ) : ""}
        </div>

        {array.length > 1 && (
          <div>
            <p className="AddProducturlLabel">URL of your product</p>
            <ThemeUploadConfirmationError
              autoFocus={"true"}
              // size="small"
              fullWidth
              onChange={(e) => secondChange(e)}
              value={secondText}
              // defaultValue={''}
              name="url2"
              placeholder="URL"

              // {...register("email")}
              onFocus={(event) => inputFocus(event)}
              onBlur={(event) => changeInput(event)}
              secondError={secondError} 
            />
            {secondText && url2Error == true ? (
              <p className="error_messge">URL is not Valid</p>
            ) : ""}
          </div>
        )}

        {array.length > 2 && (
          <div>
            <p className="AddProducturlLabel">URL of your product</p>
            <ThemeUploadConfirmationError
              autoFocus={"true"}
              // size="small"
              fullWidth
              onChange={(e) => thirdChange(e)}
              value={thirdText}
              // defaultValue={''}
              name="url3"
              placeholder="URL"
              // {...register("email")}
              onFocus={(event) => inputFocus(event)}
              onBlur={(event) => changeInput(event)}
              thirdError={thirdError}
            />
            {thirdText && url3Error == true ? (
              <p className="error_messge">URL is not Valid</p>
            ) : ""}
          </div>
        )}

        {array.length > 3 && (
          <div>
            <p className="AddProducturlLabel">URL of your product</p>
            <ThemeUploadConfirmationError
              autoFocus={"true"}
              // size="small"
              fullWidth
              value={""}
              // defaultValue={''}
              name="URL"
              placeholder="URL"
              // {...register("email")}
              onFocus={(event) => inputFocus(event)}
              onBlur={(event) => changeInput(event)}
            // error={error}
            />
          </div>
        )}

        {array.length < 3 && (
          <p
            onClick={newLinkClick}
            className="addForm_label mt-[8px]  cursor-pointer !justify-start    !font-semibold !text-[#5B5B5B] "
          >
            + Add another link
          </p>
        )}

        <div className="w-full flex justify-end">
          <ThemeAddProductErrorDialogProcessButton
            onClick={() => proceedClick()}
            fullWidth
            variant="contained"
            type="submit"
          >
            Procceed
          </ThemeAddProductErrorDialogProcessButton>
        </div>
      </div>
    </div>
  );
};

export default UploadConformationDialogBox;
