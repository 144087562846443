import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseUrl } from "../../BaseURL/BaseUrl";
export const getTodoTasks = createAsyncThunk("admin/getTodoTasks", async (type) => {
  console.log("tpy",type)
  try {
    let fd = new FormData();
    fd.append("type",type)
    const res = await axios.post(`${BaseUrl}/api/admin/gettodotasks`,fd);
    console.log(res.data.technicalTasksArrayLength, "Api Response");
    return res.data;
  } 
  catch (error) {
    console.log(error);
    throw error;
  }
});

export const getTodoTasksSlice = createSlice({
  name: "todoTasks",
  initialState: {
    tasks: [],
    designerTasksArrayLength: 0,
    technicalTasksArrayLength: 0,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTodoTasks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTodoTasks.fulfilled, (state, action) => {
        state.loading = false;
        state.tasks = action.payload.tasksArray;
        state.designerTasksArrayLength = action.payload.designerTasksArrayLength;
        state.technicalTasksArrayLength = action.payload.technicalTasksArrayLength;
      })
      .addCase(getTodoTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectLoadingTodoTasks = (state) => state.todoTasks.loading;

export default getTodoTasksSlice.reducer;
