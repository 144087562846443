import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL/BaseUrl";
const axios = require("axios");

export const updatePhotoStatus = createAsyncThunk("product/updatephotostatus", async (data) => {

 try {
  const token = JSON.parse(localStorage.getItem("DesignerToken"));
  let fd = new FormData();
  fd.append("id", data.id);
  fd.append("productPhoto", data.productPhoto);
  fd.append("status", data.status);
  fd.append("userId", data.userId);
  const res = await axios.post(
   `${BaseUrl}/api/product/updatephotostatus`,
   fd,
   {
    headers: { Authorization: "Bearer " + token },
   }
  );

  return res.data;
 } catch (error) {
  console.log(error);
 }
});

export const updatePhotoStatusSlice = createSlice({
 name: "updatePhotoStatus",
 initialState: {
  data: [],
  loading: false,
  error: false,
 },
 reducers: {},
 extraReducers: {
  [updatePhotoStatus.pending]: (state) => {
   state.loading = true;
   state.error = false;
  },
  [updatePhotoStatus.fulfilled]: (state, action) => {
   state.loading = false;
   state.data = action.payload;
  },
  [updatePhotoStatus.rejected]: (state) => {
   state.loading = false;
   state.error = true;
  },
 },
});

export default updatePhotoStatusSlice.reducer;
