import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL/BaseUrl";
const axios = require("axios");

export const addUser = createAsyncThunk("admin/adminsignup", async (data) => {
 try {
  let fd = new FormData();
  fd.append("email", data.email);
  fd.append("started", data.started);
  fd.append("userName", data.userName);
  fd.append("userType", data.userType);
  const res = await axios.post(
   `${BaseUrl}/api/admin/adminsignup`,
   fd
  );

  return res.data;
 } catch (error) {
  console.log(error);
 }
});

export const addUserAdminSlice = createSlice({
 name: "addUser",
 initialState: {
  data: [],
  loading: false,
  error: false,
 },
 reducers: {},
 extraReducers: {
  [addUser.pending]: (state) => {
   state.loading = true;
   state.error = false;
  },
  [addUser.fulfilled]: (state, action) => {
   state.loading = false;
   state.data = action.payload;
  },
  [addUser.rejected]: (state) => {
   state.loading = false;
   state.error = true;
  },
 },
});

export default addUserAdminSlice.reducer;
