import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL/BaseUrl";
import axios from "axios";

export const getInProgressTasks = createAsyncThunk(
  "tasks/getInProgressTasks",
  async (userId) => {
    try {
      const res = await axios.post(`${BaseUrl}/api/admin/getinprogresstasks`, { userId });
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getInProgressTasksSlice = createSlice({
  name: "inProgressTasks",
  initialState: {
    inProgressTasks: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getInProgressTasks.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getInProgressTasks.fulfilled]: (state, action) => {
      state.loading = false;
      state.inProgressTasks = action.payload.tastsArray;
    },
    [getInProgressTasks.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export const selectLoadingInProgressTasks = (state) => state.inProgressTasks.loading;

export default getInProgressTasksSlice.reducer;
