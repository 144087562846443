import { Diversity1Rounded, Diversity1Sharp } from "@mui/icons-material";
import { Box, Button, Paper } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import React, { useRef } from "react";
import TopBar from "../components/TopBar";
import "../css/Product.css";
import {
  textFieldStyle,
  selectField,
  productMuiButton,
  dialogTile,
  boxStyleforlogin,
  noProductBox,
  dialogcontent,
  mobiScreen,
} from "../css/style";
import noProduct from "../image/NoProductLogo.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddProductForm from "../components/AddProductForm";
import { ReactComponent as CrossIcon } from "../image/svg/CrossIcon.svg";
import { ReactComponent as ErrorCrossIcon } from "../image/svg/AddProductErrorCrossIcon.svg";
import Stack from "@mui/material/Stack";
import {
  ThemeTextField,
  ThemeDialogContent,
  ThemeDialogTitle,
  ThemeProductButtton,
  ThemeDialog,
  StyledContent,
} from "../muiTheme/Theme";
import ProductsCards from "../components/ProductsCards";
import data from "../data/data";
import "../css/style.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAll } from "../redux/Slices/GoodsBillsSlice";

import AddProductErrorDialogBox from "../components/AddProductErrorDialogBox";
import {
  ThemeAddProductErrorDialog,
  ThemeAddProductErrorDialogContent,
  ThemeAddProductErrorDialogTitle,
  ThemeDownloadPhotoDialog,
  ThemeDownloadPhotoDialogContent,
  ThemeDownloadPhotoDialogTitle,
  ThemeUploadConfirmationDialog,
} from "../muiTheme/AddProductErrordialog";
import DownloadPhotoCardsDialogBox from "../components/DownloadPhotoCardsDialogBox";
import AddProductConfirmationError from "../components/AddProductConfirmationError";
import { deleteAllPhoto } from "../redux/Slices/UploadPhotoProductSlice";
import { getAllProduct } from "../redux/Slices/GetAllProductSlice";
import UploadConformationDialogBox from "../components/UploadConformationDialogBox";
import {
  ThemeTransferDialog,
  StyledModal,
} from "../muiTheme/SingleProductTheme";

import ProductUnderReviewDialogBox from "../components/ProductUnderReviewDialogBox";

const styleButton = makeStyles((theme) => ({
  button: {
    backgroundColor: "red",
  },
}));
const Product = () => {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [errorDialogOpen, seterrorDialogOpen] = React.useState(false);
  const [DownloadPhotoOpen, setDownloadPhotoOpen] = React.useState(false);
  const [ConfirmationErrorOpen, setConfirmationErrorOpen] =
    React.useState(false);
  const [show, setShow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [confirmationErrorPhoto, setconfirmationErrorPhoto] = useState();
  const [errorDialogBills, setErrorDialogBills] = useState();
  const [downloadPhoto, setdownloadPhoto] = useState();
  const [uploadConfirmation, setuploadConfirmation] = useState(false);
  // const [closeAddProduct, setCloseAddProduct] = useState(true);
  const [productsData, setProductsData2] = useState([]);
  const dispatch = useDispatch();

  const productData2 = useSelector(
    (state) =>
      state &&
      state.allProduct &&
      state.allProduct.data &&
      state.allProduct.data.allProduct
  );

  console.log(productData2);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handledClickOpen = () => {
    setDialogOpen(true);
  };
  const handledClose = () => {
    setDialogOpen(false);
  };

  const handleOnCrossClose = () => {
    biRef.clearForm();
    setOpen(false);
  };
  var biRef = {
    handleOnCrossClose: handleOnCrossClose,

  };
  const handleClose = () => {
    dispatch(deleteAll());
    // setCloseAddProduct(false);
    setOpen(false);
    setDialogOpen(true);
    // navigate("/raf");
  };

  const ErrorDialogClose = () => {
    seterrorDialogOpen(false);
  };

  const ConfirmationErrorDialogClose = () => {
    setConfirmationErrorOpen(false);
  };

  const DownloadphotoClose = () => {
    setDownloadPhotoOpen(false);
  };

  const uploadConfirmationClose = () => {
    setuploadConfirmation(false);
  };
  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(true);
      } else {
        // if scroll up show the navbar
        setShow(false);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  const classes = styleButton();
  const trialText = "30 days left in trial";
  const text = " ";
  const iconText = "L";
  const joinButton = " ";
  useEffect(() => {
    // window.scrollTo(0, 0);
    window.scrollTo(0, 1);
    dispatch(getAllProduct());
    // console.log(getAllProduct())
  }, []);

  const closeProductDialogBox = () => {
    handleOnCrossClose();
    dispatch(deleteAllPhoto());
  };
  return (
    <div>
      <div className={`active ${show && "navclass"}`}>
        <TopBar
          Text={text}
          joinButton={joinButton}
          iconText={iconText}
          trialText={trialText}
        />
      </div>
      {/* <StyledModal
        open={dialogOpen}
        scroll="paper"
        keepMounted
        onClose={handledClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <ThemeDialogTitle>
          <div className="flex justify-end rounded-[8px 8px 0px 0px]  items-center py-[12px] px-[20px] ">
            <div>
              <span
                onClick={() => handledClose()}
                className="w-[14px] cursor-pointer"
              >
                <CrossIcon />
              </span>
            </div>
          </div>
        </ThemeDialogTitle>
        <StyledContent>
          <ProductUnderReviewDialogBox />
        </StyledContent>
      </StyledModal> */}
      <div className=" px-[42px] w-full h-full fixed">
        <div className=" flex items-center  pt-[64px] sm:mt-[32px] justify-between">
          <div
            // onClick={() => seterrorDialogOpen(true)}
            className="text-[#2C2C2C] hidden sm:block text-[28px] font-semibold"
          >
            Products
          </div>
          <div className="w-[144px] z-50 addProductMobileStyleDiv h-[44px]">
            {" "}
            <ThemeProductButtton
              onClick={handleClickOpen}
              type="submit"
              sx={productMuiButton}
              fullWidth
              variant="contained"
            >
              Add product
            </ThemeProductButtton>
          </div>
          <StyledModal
            open={open}
            scroll="paper"
            keepMounted
            disableScrollLock={true}
            // onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <ThemeDialogTitle>
              <div className="flex justify-between rounded-[8px 8px 0px 0px]  items-center py-[12px] pl-[20px] pr-[28px] ">
                <span
                  onClick={() => handleOnCrossClose()}
                  className="font-bold text-[16px] block sm:hidden text-[#31A246]"
                >
                  Back
                </span>
                <span className="font-medium text-[16px] text-[#707070]">
                  Add product
                </span>
                <div>
                  <span
                    onClick={closeProductDialogBox}
                    className="w-[14px] cursor-pointer hidden sm:block"
                  >
                    <CrossIcon className="goBackIcon cursor-pointer hover:bg-[#F5F5F5]" />
                  </span>
                </div>
              </div>
            </ThemeDialogTitle>
            <StyledContent>
              {/* {addProducFormReady && (
              <AddProductForm handleClose={handleClose} open={open} setDialogOpen={setDialogOpen} biRef={biRef} />
               )} */}
              <AddProductForm
                handleClose={handleClose}
                open={open}
                setDialogOpen={setDialogOpen}
                biRef={biRef}
              />
            </StyledContent>
          </StyledModal>

        </div>
        {productData2?.length ? (
          <div className="pt-[54px] ">
            <ProductsCards
              setconfirmationErrorPhoto={setconfirmationErrorPhoto}
              setdownloadPhoto={setdownloadPhoto}
              setConfirmationErrorOpen={setConfirmationErrorOpen}
              setDownloadPhotoOpen={setDownloadPhotoOpen}
              DownloadphotoClose={DownloadphotoClose}
              seterrorDialogOpen={seterrorDialogOpen}
              setErrorDialogBills={setErrorDialogBills}
            // closeAddProduct={open}
            />
          </div>
        ) : (
          <Box
            display={"grid"}
            justifyContent={"center"}
            justifyItems={"center"}
            alignItems={"center"}
            // border={3}
            pt={"210px"}
            gap={"20px"}
          >
            <div>
              <img className="w-[86px] h-[80px]" src={noProduct} alt="" />
            </div>
            <p className="text-[#9D9D9D] mb-[29px] font-medium text-[16px] md:text-[24px]">
              No products to display
            </p>
          </Box>
        )}

        <ThemeAddProductErrorDialog
          open={errorDialogOpen}
          scroll="paper"
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <ThemeAddProductErrorDialogTitle>
            <div className="flex justify-between rounded-[8px 8px 0px 0px]  items-center py-[12px] pl-[20px] pr-[28px] ">
              <div>
                <p className="addProductErrorDialogTile">Add Product Error</p>
              </div>
              <div>
                <span
                  onClick={ErrorDialogClose}
                  className="w-[14px] cursor-pointer hidden sm:block"
                >
                  <ErrorCrossIcon className=" cursor-pointer " />
                </span>
              </div>
            </div>
          </ThemeAddProductErrorDialogTitle>
          <ThemeAddProductErrorDialogContent>
            <AddProductErrorDialogBox
              errorDialogBills={errorDialogBills}
              seterrorDialogOpen={seterrorDialogOpen}
            />

          </ThemeAddProductErrorDialogContent>
        </ThemeAddProductErrorDialog>

        {/* ............................................................................................................................ */}

        <ThemeDownloadPhotoDialog
          open={DownloadPhotoOpen}
          scroll="paper"
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <ThemeDownloadPhotoDialogTitle>
            <div className="flex justify-between rounded-[8px 8px 0px 0px]  items-center py-[12px] pl-[20px] pr-[28px] ">
              <div>
                <p className="addProductErrorDialogTile !text-[#707070]">
                  Download photos
                </p>
              </div>
              <div>
                <span
                  onClick={() => DownloadphotoClose()}
                  className="w-[14px] cursor-pointer hidden sm:block"
                >
                  <CrossIcon className=" cursor-pointer hover:bg-[#F5F5F5]" />
                </span>
              </div>
            </div>
          </ThemeDownloadPhotoDialogTitle>
          <ThemeDownloadPhotoDialogContent>
            <DownloadPhotoCardsDialogBox
              setuploadConfirmation={setuploadConfirmation}
              DownloadphotoClose={DownloadphotoClose}
              downloadPhoto={downloadPhoto}
            />
          </ThemeDownloadPhotoDialogContent>
        </ThemeDownloadPhotoDialog>

        {/* ................................................................................................................. */}

        <ThemeAddProductErrorDialog
          open={ConfirmationErrorOpen}
          scroll="paper"
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <ThemeAddProductErrorDialogTitle>
            <div className="flex justify-between rounded-[8px 8px 0px 0px]  items-center py-[12px] pl-[20px] pr-[28px] ">
              <div>
                <p className="addProductErrorDialogTile">Confirmation Error</p>
              </div>
              <div>
                <span
                  onClick={() => ConfirmationErrorDialogClose()}
                  className="w-[14px] cursor-pointer hidden sm:block"
                >
                  <ErrorCrossIcon className=" cursor-pointer " />
                </span>
              </div>
            </div>
          </ThemeAddProductErrorDialogTitle>
          <ThemeAddProductErrorDialogContent>
            <AddProductConfirmationError
              confirmationErrorPhoto={confirmationErrorPhoto}
              setConfirmationErrorOpen={setConfirmationErrorOpen}
              seterrorDialogOpen={seterrorDialogOpen}
            />
          </ThemeAddProductErrorDialogContent>
        </ThemeAddProductErrorDialog>

        {/* ................................................. */}
        <ThemeUploadConfirmationDialog
          open={uploadConfirmation}
          scroll="paper"
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <ThemeDownloadPhotoDialogTitle>
            <div className="flex justify-between rounded-[8px 8px 0px 0px]  items-center py-[12px] pl-[20px] pr-[28px] ">
              <div>
                <p className="addProductErrorDialogTile !text-[#707070]">
                  Upload Confirmation
                </p>
              </div>
              <div>
                <span
                  onClick={() => uploadConfirmationClose()}
                  className="w-[14px] cursor-pointer hidden sm:block"
                >
                  <CrossIcon className=" cursor-pointer hover:bg-[#F5F5F5]" />
                </span>
              </div>
            </div>
          </ThemeDownloadPhotoDialogTitle>
          <ThemeDownloadPhotoDialogContent>
            <UploadConformationDialogBox
              downloadPhoto={downloadPhoto}
              setuploadConfirmation={setuploadConfirmation}
            />
          </ThemeDownloadPhotoDialogContent>
        </ThemeUploadConfirmationDialog>
      </div>
    </div>
  );
};

export default Product;
