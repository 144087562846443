import { AppBar, Box, Divider, Menu, MenuItem, Toolbar } from "@mui/material";
import React from "react";
import "../css/style.css";
import "../css/profile.css";
import { ReactComponent as ErrorCrossIcon } from "../image/svg/AddProductErrorCrossIcon.svg";
import { ReactComponent as Certified } from "../image/svg/Certified.svg";
import { ReactComponent as Logo } from "../image/svg/Frame 9165.svg";
import { useNavigate } from "react-router-dom";
import { ThemeAppbar, ThemeAvatar } from "../muiTheme/Theme";
import Avatar from "@mui/material/Avatar";
import {
  ThemeDeleteAccountPopsDialogTitle,
  ThemeDeleteAcountDialog,
  ThemeProfileMenu,
  ThemeProfileMenuAvatar,
  ThemeProfileMenuDivider,
  ThemeProfileMenuDivider2,
  ThemeProfileMenuItem,
  ThemeProfileMenuItemDeletAcount,
} from "../muiTheme/ProfileTheme";
import {
  ThemeAddProductErrorDialog,
  ThemeAddProductErrorDialogContent,
  ThemeAddProductErrorDialogTitle,
} from "../muiTheme/AddProductErrordialog";
import DeleteAccountDialogBox from "./DeleteAccountDialogBox";
import { ReactComponent as CrossIcon } from "../image/svg/CrossIcon.svg";

const TopBarSimple = (token) => {
  const navigate = useNavigate();
  const [deleteDialogOpen, setdeleteDialogOpen] =
    React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    // navigate("/profileview");

    setAnchorEl(null);
  };
  const openProfile = () => {
    navigate("/profileview");

    setAnchorEl(null);
  };
  const handleSignOut = () => {
    localStorage.clear();
    navigate("/Staff-Login");

    setAnchorEl(null);
  };
  const deletePopClose = () => {
    setdeleteDialogOpen(false);
  };

  const deletePopsOpen = () => {
    setdeleteDialogOpen(true);
    setAnchorEl(null);

  };
  const items = JSON.parse(localStorage.getItem("user"));

  // const data= Array.from(items)[0];
  return (
    <>
      <Box>
        <ThemeAppbar>
          <Toolbar>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              className="Box"
            >
              <div
                onClick={() => navigate("/")}
                className="flex justify-center cursor-pointer items-center justify-items-center gap-[2px]"
              >
                <Certified />
                <Logo />
              </div>

              <div className="flex items-center  ">

                <span
                  onClick={() =>
                    handleSignOut()
                  }
                  className=" textButton text-[#31A246] hover:text-[#266E20] hover:underline underline-offset-[2px] cursor-pointer"
                >
                  {token.token ? "Sign Out" : " "}
                </span>
                {/* {trialText && (
                <span className=" hidden sm:block textButton text-[#31A246] ">
                  {trialText}
                </span>
              )} */}
              </div>

              {/* ******************************** */}
            </Box>
          </Toolbar>
        </ThemeAppbar>

      </Box>
    </>
  );
};

export default TopBarSimple;
