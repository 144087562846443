import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL/BaseUrl";
const axios = require("axios");

export const deleteUser = createAsyncThunk("admin/delAdmin", async (data) => {

 try {
  let fd = new FormData();
  fd.append("userId", data);

  const res = await axios.post(
   `${BaseUrl}/api/admin/deladmin`,
   fd
  );
  return res.data;
 } catch (error) {
  console.log(error);
 }
});

export const delUserAdminSlice = createSlice({
 name: "deleteUser",
 initialState: {
  data: [],
  loading: false,
  error: false,
 },
 reducers: {},
 extraReducers: {
  [deleteUser.pending]: (state) => {
   state.loading = true;
   state.error = false;
  },
  [deleteUser.fulfilled]: (state, action) => {
   state.loading = false;
   state.data = action.payload;
  },
  [deleteUser.rejected]: (state) => {
   state.loading = false;
   state.error = true;
  },
 },
});