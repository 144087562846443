import { ReactComponent as PdfIcon } from "../image/svg/AddProductErrorDialogIcon.svg";
import { ReactComponent as DownloadIcon } from "../image/svg/DownloadIcon.svg";
import React, { useState } from "react";
import "../css/UploadPhotos.css";
import {
  ThemeTextField,
  ThemeDialogContent,
  ThemeDialogTitle,
  ThemeProductButtton,
  ThemeDialog,
  ThemeButton,
} from "../muiTheme/Theme";
import { Navigate, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useRef } from "react";
import "../css/addProductForm.css";
import { useEffect } from "react";
import { ReactComponent as CrossIcon } from "../image/svg/BillCrossIcon.svg";
import { inputFocus, changeInput } from "../muiTheme/OnFilledBorderStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAllPhoto,
  uploadPhoto,
} from "../redux/Slices/UploadPhotoProductSlice";
import ConfirmtionModal from "./ConfirmtionModal";
import { addPhoto } from "../redux/Slices/AddPhotoSlice";
import { deleteProductPhoto } from "../redux/Slices/ProductPhotoDeleteSlice";
import { BaseUrl } from "../BaseURL/BaseUrl";


var imagesAdded = [];
var formGoActiv = false;
var activImageIndex;

const thsDevice = navigator.platform;

const UploadPhoto = ({ open, handleClose, value }) => {
  const navigate = useNavigate();
  const [type, settype] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const [photo, setphoto] = useState(null);
  const inputRef = useRef();
  const inputFile = useRef(null);
  const [pdfProgress, setPdfProgress] = useState(0);
  const data = useSelector((state) => state.photo);
  const billsData = useSelector((state) => state.bills);
  const dispatch = useDispatch();
  var newData = [];


  useEffect(() => {
    imagesAdded = [];
  }, [open]);

  const onButtonClick = (val) => {
    activImageIndex = val;
    settype(val);
    // imagesAdded.forEach((item) => {
    //   item.active = false;
    // });
    // imagesAdded = imagesAdded.filter((a) => a.btn != val);

    // `current` points to the mounted file input element;
    inputFile.current.click();
  };

  const dialogopenFocus = () => {
    const targett = document.getElementById("productNameFocusClass");
    if (targett) {
      targett.focus();
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const target = document.getElementById("pImgError");
    const file = event.dataTransfer.files[0];
    const fileSize = (file.size / 1024 / 1024).toFixed(2);
    if (fileSize > 2) {
      target.innerHTML = "File size exceeds 2MB. Try a smaller file";
      target.classList.remove("noDisplay");
      target.classList.add("error_box");
      return;
    } else {
      target.classList.add("noDisplay");
      target.classList.remove("error_box");
      const reader = new FileReader();
      reader.onload = () => {
        setphoto(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(uploadPhoto({ file }));
      const type = 1;
      dispatch(addPhoto({ file, type }))
        .unwrap()
        .then((res) => {
          if (res?.success) {
            setphoto(res.uploadPath);
            // setphotosName([
            //   ...photosName,
            //   { path: res.uploadPath, status: "review", type: res.type },
            // ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSendInParent = () => {
    // console.log("presseed")
    setphoto(null);
  };

  const handleSelect = (event) => {
    event.preventDefault();
    const target = document.getElementById("pImgError");
    const file = event.target.files[0];
    const fileSize = (file.size / 1024 / 1024).toFixed(2);
    if (fileSize > 2) {
      target.innerHTML = "File size exceeds 2MB. Try a smaller file";
      target.classList.remove("noDisplay");
      target.classList.add("error_box");
      return;
    } else {
      target.classList.add("noDisplay");
      target.classList.remove("error_box");
      const reader = new FileReader();
      reader.onload = () => {
        // setphoto(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(addPhoto({ file, type }))
        .unwrap()
        .then((res) => {
          if (res?.success) {
            setphoto(res.uploadPath);
            // setphoto(res.uploadpath);
            // setphotosName([
            //   ...photosName,
            //   { path: res.uploadPath, status: "review", type: res.type },
            // ]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deletePhoto = () => {
    // dispatch(deletePhoto());
    dispatch(deleteProductPhoto(photo));
    setphoto(null);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `${BaseUrl}/public/productImages/${value.path}`;


    // Set the download attribute based on the file name
    const fileName = value.path; // Set the desired file name
    link.setAttribute('download', fileName);

    // Convert the image to a data URL
    fetch(link.href)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        link.href = url;

        // Simulate a click on the link to trigger the download
        link.click();

        // Clean up by revoking the object URL
        URL.revokeObjectURL(url);
      });
  };

  const handleClickOpenModal = () => {
    setOpenModal(true);
    // console.log('open')
  };
  const handleCloseModal = () => {
    // dispatch(deleteAll());
    // setCloseAddProduct(false);
    handleClose();
    setSecondModalOpen(true);
  };

  const handleUploadModalClose = () => {
    dispatch(deleteProductPhoto(photo));
    setphoto(null);
    handleClose();

  };
  const closeProductDialogBox = () => {
    dispatch(deleteProductPhoto(photo));
    setphoto(null);
    setSecondModalOpen(false);
    // dispatch(deleteAllPhoto());
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dialogopenFocus();
  }, [open]);

  useEffect(() => {
    formGoActiv = false;
  }, [handleClose]);

  return (
    <div>
      <div className="mainDiv">
        <div className="uploadphotosbox1 !mt-0 !m-0 py-6 px-5 w-full">
          <div>
            {!photo ? (
              <div>
                <div className="singleTextBoxDiv !w-full">
                  <p id="pImgError" className=" noDisplay"></p>
                  <div>

                    <p className="AddProductErrorDownloadPhotoLabel  pb-[16px]">
                      You can still download the photo to photoshop it.{" "}
                    </p>


                    <div className="flex justify-between">
                      <div className="flex gap-[8px] items-center">
                        <p>
                          <PdfIcon />
                        </p>
                        <p className="addProductErrorFileName">
                          {newData.length > 0 && newData[0].id}
                        </p>
                        <p className="pdfSize">{value.path}</p>
                      </div>
                      <p>
                        <DownloadIcon
                          onClick={handleDownload}
                          className=" cursor-pointer  hover:bg-[#F5F5F5]"
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  onDrop={handleDrop}
                  onDragOver={(event) => event.preventDefault()}
                  className="dropbox mt-5"
                >
                  <h1
                    style={{
                      fontSize: "22px",
                      fontWeight: "bold",
                      lineHeight: "28px",
                    }}
                  >
                    Drag & Drop the photo here
                  </h1>
                  <h3>Upload JPG or PNG files up to 2MB</h3>
                  <div className="flex items-center">
                    <p>
                      <span className="word-line">or</span>
                    </p>
                  </div>
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    onChange={handleSelect}
                    hidden
                  // ref={inputRef}
                  />
                  <ThemeButton
                    // onClick={() => inputRef.current.click()}
                    onClick={() => onButtonClick(1)}
                    variant="contained"
                  >
                    Choose File
                  </ThemeButton>
                </div>
              </div>
            ) : (
              <div>
                <div
                  className="dropbox mb-5"
                  style={{ height: "188px", padding: "8px 0px" }}
                >
                  <img
                    className="addMyTasksImageTag absolute "
                    src={`${BaseUrl}/public/productImages/${photo}`}
                    alt=""
                  />
                </div>
                <div className="flex justify-between">
                  <ThemeButton
                    id="btn2"
                    variant="contained"
                    onClick={() => deletePhoto()}
                  >
                    Go back
                  </ThemeButton>
                  <ThemeButton
                    id="btn1"
                    variant="contained"
                    // onClick={() => setSecondModalOpen(true)}
                    onClick={() => handleCloseModal()}
                  >
                    Send to customer
                  </ThemeButton>
                </div>

                <ThemeDialog
                  open={secondModalOpen}
                  scroll="paper"
                  keepMounted
                  // onClose={handleClose}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <ThemeDialogTitle>
                    <div className="flex justify-between rounded-[8px 8px 0px 0px]  items-center py-[12px] pl-[20px] pr-[28px] ">
                      <span
                        onClick={() => closeProductDialogBox()}
                        className="font-bold text-[16px] block sm:hidden text-[#31A246]"
                      >
                        Back
                      </span>
                      <span
                        className="font-medium text-[16px] text-[#707070]"
                        style={{ fontWeight: "bold" }}
                      >
                        Send Photo
                      </span>
                      <div>
                        <span
                          onClick={closeProductDialogBox}
                          className="w-[14px] cursor-pointer hidden sm:block"
                        >
                          <CrossIcon
                            className="goBackIcon cursor-pointer hover:bg-[#F5F5F5]"
                            style={{ width: "28px", height: "28px" }}
                          />
                        </span>
                      </div>
                    </div>
                  </ThemeDialogTitle>
                  <ThemeDialogContent>
                    <ConfirmtionModal
                      handleClose={closeProductDialogBox}
                      open={openModal}
                      text={"Are you sure you want to send the photo?"}
                      buttontext={"Send"}
                      updatedPhoto={photo}
                      value={value}
                      onSendClick={handleSendInParent}
                    />
                  </ThemeDialogContent>
                </ThemeDialog>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadPhoto;;;;