import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL/BaseUrl";
import axios from "axios";

export const getCompany = createAsyncThunk(
  "company/getcompany",
  async (companyId) => {
    try {
      const response = await axios.post(`${BaseUrl}/api/company/getcompany`, {
        companyId,
      });

      return response.data;
    } catch (error) {
      console.log(error, "ERROROORR");
      throw error;
    }
  }
);

const getCompanySlice = createSlice({
  name: "getCompany",
  initialState: {
    data: [],
    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompany.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getCompany.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export default getCompanySlice.reducer;
