import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { ReactComponent as CrossIcon } from "../image/svg/CrossIcon.svg";
import { useRef, useContext } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAll } from "../redux/Slices/UploadPhotoSlice";
import { Document, Page } from 'react-pdf';

const ImagePreviewer = ({ setOpen, image }) => {
  const dispatch = useDispatch();
  const result = useSelector((state) => state.photo.photo);
  console.log(result, "result photoo");
  const url = document.URL;
  console.log(url, "urllll");

  const crossIconClick = () => {
    setOpen(false);
    dispatch(deleteAll());
  };

  return (
    <div className="mainDiv ">
      <div className="w-full">
        {/* <div className="tranferFormTopbar w-full flex justify-end">
          <p>
            <CrossIcon onClick={crossIconClick} />
          </p>
        </div> */}
        <div className=" productTransferFormDiv !mt-0 !m-0 !py-6  !px-5">
          <iframe src={image} alt="My Image" className="w-[100%] h-screen relative"/>
        </div>
      </div>
    </div>
  );
};

export default ImagePreviewer;
