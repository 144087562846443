import React, { useState } from "react";
import "../css/UploadPhotos.css";
import { ThemeTextField, ThemeButton } from "../muiTheme/Theme";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useRef } from "react";
import "../css/addProductForm.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePhotoStatus } from "../redux/Slices/UpdatePhotoStatusSlice";
import { GetShowPhotosWithReviews } from "../redux/Slices/GetShowPhotosWithReviewsSlice";
import { resetDownloaded, setDownloaded } from "../redux/Slices/onSendConfirmationSlice";


var imagesAdded = [];
var formGoActiv = false;
const thsDevice = navigator.platform;

const ConfirmtionModal = ({ open, handleClose, text, buttontext, updatedPhoto, value }) => {
  const [data, setData] = useState(null);

  const allPhotosWithReviews = useSelector(
    (state) => state.allPhotosWithReviews.data
  );

  useEffect(() => {


    const modifiedObject = allPhotosWithReviews.find(obj => obj.proId === value.proId);
    if (modifiedObject) {
      const modifiedPhotos = modifiedObject.photos.map(photo => {
        if (photo.path === value.path) {
          return { path: updatedPhoto, status: 'done', type: photo.type };
        }
        return photo;
      });
      setData(modifiedPhotos);
    }
  }, [allPhotosWithReviews]);


  const dispatch = useDispatch();

  const dispatchToSend = (data1) => {
    dispatch(updatePhotoStatus(data1))
      .unwrap()
      .then((res) => {
        if (res?.success) {
          handleClose();
          dispatch(setDownloaded(false));
          dispatch(GetShowPhotosWithReviews());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickSendButton = () => {
    console.log("Button Pressed")
    if (data) {
      let allDone = true;
      for (let i = 0; i < data.length; i++) {
        if (data[i].status !== 'done') {
          allDone = false;
          break;
        }
      }

      const JsonString = JSON.stringify(data);
      const userId = JSON.parse(localStorage.getItem("userId"));
    
      if (allDone) {
        const obj = {
          id: value.proId,
          productPhoto: JsonString,
          status: "download",
          userId: userId
        };
        dispatchToSend(obj);
      } else {
    
        const obj = {
          id: value.proId,
          productPhoto: JsonString,
          status: "review",
          userId: userId
        };
        dispatchToSend(obj);
      }
    }
    
  };

  useEffect(() => {
    imagesAdded = [];
  }, [open]);

  const dialogopenFocus = () => {
    const targett = document.getElementById("productNameFocusClass");
    if (targett) {
      targett.focus();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dialogopenFocus();
  }, [open]);

  useEffect(() => {
    formGoActiv = false;
  }, [handleClose]);

  return (
    <div>
      <div className="mainDiv">
        <div className="sendphotomodal !mt-0 !m-0 py-6 px-5 w-full">
          <div className="py-5">
            <p className="AddProductErrorDownloadPhotoLabel  pb-[16px] ">
              {text}
            </p>
          </div>
          <div className="flex justify-between">
            <ThemeButton
              id="btn2"
              variant="contained"
              onClick={handleClose}
              style={{ width: "116px", marginLeft: "66px" }}
            >
              Cancel
            </ThemeButton>
            <ThemeButton
              id="btn1"
              variant="contained"
              onClick={handleClickSendButton}

              style={{ width: "116px" }}
            >
              {buttontext}
            </ThemeButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmtionModal;
