import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL/BaseUrl";
const axios = require("axios");

export const getAllTasks = createAsyncThunk(
  "tasks/gettasks",
  async (selectedItem) => {
    try {
      let fd = new FormData();
      fd.append("userId", selectedItem._id);
      fd.append("userType", selectedItem.userType);
      const res = await axios.post(`${BaseUrl}/api/admin/gettasks`, fd);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const getAllTasksSlice = createSlice({
  name: "alltasks",
  initialState: {
    todoTasks: [],
    inProgressTasks: [],
    doneTasks: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllTasks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllTasks.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.todoTasks = action.payload.todoTasks;
        state.inProgressTasks = action.payload.inProgressTasks;
        state.doneTasks = action.payload.doneTasks;
      })
      .addCase(getAllTasks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectLoadingTasks = (state) => state.alltasks.loading;

export default getAllTasksSlice.reducer;
