import { useState } from "react";

function Tabs({ onTabChange }) {
  const [activeTab, setActiveTab] = useState("All");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    onTabChange(tabName);
  };


  return (
    <div className="items-center">
      <div className="bg-transparent ">
        <div className="flex">
          <button
            className={`px-4 py-2 border-b-2 ${
              //   activeTab === "All" ? "bg-gray-200" : "" ,
              activeTab === "All" ? "border-green-400" : "text-gray-400"
              }`}
            onClick={() => handleTabClick("All")}
          >
            All
          </button>
          <button
            className={`px-4 py-2 border-b-2 ${
              //   activeTab === "Designer" ? "bg-gray-200" : ""
              activeTab === "Designer" ? "border-green-400" : "text-gray-400"
              }`}
            onClick={() => handleTabClick("Designer")}
          >
            Designer
          </button>
          <button
            className={`px-4 py-2 border-b-2 ${activeTab === "Technical" ? "border-green-400" : "text-gray-400"
              }`}
            onClick={() => handleTabClick("Technical")}
          >
            Technical
          </button>
        </div>
        {/* <div className="p-4">
          {activeTab === "All" && <p>All tab content goes here</p>}
          {activeTab === "Designer" && <p>Designer tab content goes here</p>}
          {activeTab === "Technical" && (
            <p>Technical tab content goes here</p>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default Tabs;