
import { React, useCallback, useEffect, useMemo, useState } from 'react';
import TopBar from "../components/TopBar";
import { ThemeAddUserIconButton, ThemeDialog, ThemeDialogContent, ThemeDialogTitle } from '../muiTheme/Theme';
import { ReactComponent as AddUserIcon } from '../image/svg/AddUserIcon.svg';
import { ReactComponent as UsersIcon } from '../image/svg/UsersIcon.svg';
import Tabs from '../components/Tabs';
import AddUserModalForm from "../components/AddUserModalForm";
import { ReactComponent as CrossIcon } from "../image/svg/CrossIcon.svg";
import { ReactComponent as ThreeVerticalDots } from "../image/svg/ThreeVertalDots.svg";
import UserView from '../components/UserView';
import TopBarSimple from "../components/TopBarSimple";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersAdmin } from "../redux/Slices/getAllUsersAdminSlice";
import PaginationAdminPanel from "../components/PaginationAdminPanel";
import UserPaginationContext from "../useContext/UserPaginationContext";
import { GetShowPhotosWithReviews } from "../redux/Slices/GetShowPhotosWithReviewsSlice";

function AdminPanel() {
  const [formData, setFormData] = useState(null);
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState("Active");
  const [users, setUsers] = useState([]);
  const [designer, setDesigner] = useState([]);
  const [technical, setTechnical] = useState([]);
  const [dataFromTabs, setDataFromTabs] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const token = JSON.parse(localStorage.getItem("AdminToken"));
  const dispatch = useDispatch();
  const allUsersAdmin = useSelector((state) => state.allUsers);
  // console.log(allUsersAdmin, "allUsersAdmin");
  // const UserAdmin_request = useSelector(state => state);
  // const UserAdmin_Success = useSelector(state => state.UserInfo_Reducer.UserInfo_Success);
  function usePagination(data, itemsPerPage) {

    const pageCount = useMemo(() => Math.ceil(data.length / itemsPerPage), [data.length, itemsPerPage,]);


    const handlePrev = useCallback(() => {
      setCurrentPage((currentPage) => currentPage - 1);
    }, []);

    const handleNext = useCallback(() => {
      setCurrentPage((currentPage) => currentPage + 1);
    }, []);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = useMemo(() => data.slice(startIndex, endIndex), [data, startIndex, endIndex,]);

    return {
      currentData,
      currentPage,
      handlePrev,
      handleNext,
      pageCount,
    };
  }
  const usersPagination = usePagination(users, 10);
  const technicalPagination = usePagination(technical, 10);
  const designerPagination = usePagination(designer, 10);

  const handleDataFromTabs = (data) => {
    setDataFromTabs(data);
  };
  const handleFormSubmit = (data) => {
    setFormData(data);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeProductDialogBox = () => {
    handleClose();
  };

  const filteredUsers = users.filter((user) => {
    const { userName, userType } = user;
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      userName.toLowerCase().includes(lowerCaseSearchTerm) ||
      userType.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });

  const searchPagination = usePagination(filteredUsers, 10);


  useEffect(() => {
    dispatch(getAllUsersAdmin());
  }, []);

  useEffect(() => {
    const getAllUsers = allUsersAdmin?.data?.users;
    const getDesigners = allUsersAdmin?.data?.users?.filter((obj) => obj.userType == 'Designer');
    const getTechnical = allUsersAdmin?.data?.users?.filter((obj) => obj.userType == 'Technical');
    if (allUsersAdmin.data.success == true) {
      setUsers(getAllUsers);
      setTechnical(getTechnical);
      setDesigner(getDesigners);
    }
  }, [allUsersAdmin]);


  return (
    <div className='setProjectBg'>
      <div>
        <TopBarSimple token={token} />
      </div>
      <div className="px-[42px]">
        <div className="d-flex flex-column pt-[64px] sm:mt-[32px] justify-between">
          <div className='text-[#2C2C2C] hidden sm:block text-[28px] font-semibold'>
            Users
          </div>
          <div className="flex flex-nowrap justify-between">
            <div className="flex flex-nowrap " >
              <Tabs onTabChange={handleDataFromTabs} />
            </div>
            <div className="flex">
              <div className='mx-1'>
                Total Users:
              </div>
              <div>

                {
                  dataFromTabs == "All" ? (users.length) : (dataFromTabs == "Technical" ? (technical.length) : (dataFromTabs == "Designer" ? (designer.length) : (null)))
                }
              </div>
            </div>
          </div>
          <div className="border border-gray-200"></div>


          <div className='p-4 mt-4 bg-white  border border-gray-200 rounded-t-lg'>
            <div className='flex justify-between'>
              <div className="flex flex-nowrap bg-gray-100 rounded-md h-6 items-center p-4">
                <svg className="h-4 w-auto text-gray-400" fill="none" strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M10 18a8 8 0 100-16 8 8 0 000 16z" />
                  <path d="M21 21l-5.2-5.2" />
                </svg>
                <input
                  className="bg-gray-100 ml-1 w-48 py-1 px-2 text-sm text-gray-600 focus:outline-none"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  type="text"
                  placeholder="Search Users by Name or Role" />
              </div>
              <div>
                <ThemeAddUserIconButton
                  onClick={handleClickOpen}
                  type="submit"
                  // sx={productMuiButton}
                  fullWidth
                  variant="contained"
                >
                  <AddUserIcon />
                  Add User
                </ThemeAddUserIconButton>
              </div>
            </div>
            <ThemeDialog
              open={open}
              scroll="paper"
              keepMounted
              // onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <ThemeDialogTitle>
                <div className="flex justify-between rounded-[8px 8px 0px 0px]  items-center py-[12px] pl-[20px] pr-[28px] ">
                  <span
                    onClick={() => handleClose()}
                    className="font-bold text-[16px] block sm:hidden text-[#31A246]"
                  >
                    Back
                  </span>
                  <span className="font-medium text-[16px] text-[#707070]">
                    Add User
                  </span>
                  <div>
                    <span
                      onClick={closeProductDialogBox}
                      className="w-[14px] cursor-pointer hidden sm:block"
                    >
                      <CrossIcon className="goBackIcon cursor-pointer hover:bg-[#F5F5F5]" />
                    </span>
                  </div>
                </div>
              </ThemeDialogTitle>
              <ThemeDialogContent>
                <AddUserModalForm handleClose={handleClose} open={open} onSubmit={handleFormSubmit} />
              </ThemeDialogContent>
            </ThemeDialog>
          </div>

          <div className="">
            {searchTerm && filteredUsers.length > 0 ? (
              <UserView active={active} formData={searchPagination.currentData} />
            ) : dataFromTabs === 'All' ? (
              users ? (
                <UserView active={active} formData={usersPagination.currentData} />
              ) : (
                <div className="flex flex-col items-center justify-center h-screen">
                  <UsersIcon />
                  <p className="text-gray-500">No users to display</p>
                </div>
              )
            ) : dataFromTabs === 'Technical' ? (
              technical ? (
                <UserView active={active} formData={technicalPagination.currentData} />
              ) : (
                <div className="flex flex-col items-center justify-center h-screen">
                  <UsersIcon />
                  <p className="text-gray-500">No users to display</p>
                </div>
              )
            ) : dataFromTabs === 'Designer' ? (
              designer ? (
                <UserView active={active} formData={designerPagination.currentData} />
              ) : (
                <div className="flex flex-col items-center justify-center h-screen">
                  <UsersIcon />
                  <p className="text-gray-500">No users to display</p>
                </div>
              )
            ) : null}
          </div>


          {
            dataFromTabs === "All" ?
              <UserPaginationContext.Provider value={usersPagination}>
                <PaginationAdminPanel />
              </UserPaginationContext.Provider>
              :
              dataFromTabs === "Designer" ?
                <UserPaginationContext.Provider value={designerPagination}>
                  <PaginationAdminPanel />
                </UserPaginationContext.Provider>
                :
                dataFromTabs === "Technical" ?
                  <UserPaginationContext.Provider value={technicalPagination}>
                    <PaginationAdminPanel />
                  </UserPaginationContext.Provider>
                  :
                  (null)
          }


        </div>
      </div>
    </div>
  );
}

export default AdminPanel;