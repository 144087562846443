import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseUrl } from "../../BaseURL/BaseUrl";

export const updateProductUrl = createAsyncThunk(
  "product/updateProductUrl",
  async ({ id, productUrlStatus, userId, index }) => {
    try {
      const token = JSON.parse(localStorage.getItem("TechnicalToken"));
      let fd = new FormData();
      fd.append("id", id);
      fd.append("productUrlStatus", productUrlStatus);
      fd.append("index", index);
      fd.append("userId", userId);
      const response = await axios.post(
        `${BaseUrl}/api/product/updateproducturl`,
        fd,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      console.log(response.data.success, "rsp");
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const productUrlSlice = createSlice({
  name: "updateproducturl",
  initialState: {
    data: [],
    loading: false,
    error: null,
    allProductUrlsDone: false, // Add a new property to store allProductUrlsDone value
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateProductUrl.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProductUrl.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.updatedProduct;
        state.allProductUrlsDone = action.payload.allProductUrlsDone; // Set the allProductUrlsDone value from the API response
      })
      .addCase(updateProductUrl.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default productUrlSlice.reducer;
