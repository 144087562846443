import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const initialState = {
  dataError: [],
};

export const errorsDataSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    addDataError: (state, action) => {
      const { data1, data2, data3, data4, data5 } = action.payload;
      if (data1 == "error") {
        let imgs = data4.map(val => val.path);
        state.dataError.unshift({ type: data1, bills: data2, url: data3, photo: imgs, id: data5 });
        // state.dataError.unshift({ type: data1, id: data2, url: data3, photo: data4[1].path });
        // state.dataError.unshift({ type: data1, id: data2[1], url: data3 });
      }
      if (data1 == "download") {
        for (let i = 0; i < data2.length; i++) {
          state.dataError.unshift({ type: data1, photo: data2[i].path, name: data3 });
        }
      }
      console.log(action.payload, "action reduz");

      // state.Bills.unshift({ file, fileSize, active: true });
    },
  },
});

export const { addDataError } = errorsDataSlice.actions;

export default errorsDataSlice.reducer;
