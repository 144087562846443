import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL/BaseUrl";
const axios = require("axios");


export const passwordAdminResetLink = createAsyncThunk(
 "admin/signup",
 async (data) => {
  console.log(data, "verify data redixx");
  try {
   let fd = new FormData();
   fd.append("email", data.email);
   fd.append("type", data.type);

   const res = await axios.post(`${BaseUrl}/api/admin/forgotcode`, fd);

   console.log(res, "reduxx passwordResetLinkp");
   return res.data;
  } catch (error) {
   console.log(error);
  }
 }
);

export const passwordAdminResetLinkSlice = createSlice({
 name: "passwordAdminResetLink",
 initialState: {
  data: [],
  loading: false,
  error: false,
 },
 reducers: {},
 extraReducers: {
  [passwordAdminResetLink.pending]: (state) => {
   state.loading = true;
   state.error = false;
  },
  [passwordAdminResetLink.fulfilled]: (state, action) => {
   state.loading = false;
   state.data = action.payload;
  },
  [passwordAdminResetLink.rejected]: (state) => {
   state.loading = false;
   state.error = true;
  },
 },
});

export default passwordAdminResetLink.reducer;

