import React from "react";
import { ThemeButton } from "../muiTheme/Theme";
function MessageConfirmationBox({ innerText, onClick }) {
  return (
    <div className="my-[40px] p-[20px] botto-box">
      <span className="box-text">{innerText}</span>
      <ThemeButton variant="contained" fullWidth onClick={onClick}>
        Confirm
      </ThemeButton>
    </div>
  );
}

export default MessageConfirmationBox;
