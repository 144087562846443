import React from 'react'
import { useEffect  } from 'react'
import { useNavigate } from 'react-router-dom'

const Raf = () => {
   const navigate =  useNavigate()
    useEffect(() => {
        localStorage.setItem('productAdded', true);
        navigate('/products')

    }, [])
    
  return (
    <div>
      
    </div>
  )
}

export default Raf
