import React from "react";
import "../css/productDetail.css";
import { ReactComponent as TransferIcon } from "../image/svg/TransferDoneIcon.svg";

const ProductTransferDialogBox = () => {
  return (
    <div>
      <div className="transferDialogMianDiv">
        <div className="mb-4 sm:mb-0 sm:mr-4">
          <TransferIcon />
        </div>
        <div>
          <p className="transferDialogTitle text-center sm:text-left">Product transferred</p>
          <p className="transferDialogSubTitle pt-2 text-center sm:text-left sm:pt-0">
            Within 24 hours, it will be transferred to the company name
          </p>
        </div>
      </div>
    </div>

  );
};

export default ProductTransferDialogBox;
