import React, { useState } from "react";
import { useRef } from "react";
import { ReactComponent as InfoIcon } from "../image/svg/InfoIcon.svg";
import { ReactComponent as PdfIcon } from "../image/svg/AddProductErrorDialogIcon.svg";
import { ReactComponent as CrossIcon } from "../image/svg/BillCrossIcon.svg";
import { useDispatch, useSelector } from "react-redux";

import "../css/addProductForm.css";
import { BaseUrl } from "../BaseURL/BaseUrl";
import { ThemeAddProductErrorDialogProcessButton } from "../muiTheme/AddProductErrordialog";
import { deleteBills, uploadBill } from "../redux/Slices/GoodsBillsSlice";
import { addBills } from "../redux/Slices/AddBillsSlice";

import { ReactComponent as DownloadIcon } from "../image/svg/DownloadIcon.svg";
import { ThemeLinearProgress } from "../muiTheme/Theme";
import { updateProduct } from "../redux/Slices/UpdateProductSlice";
import { getAllProduct } from "../redux/Slices/GetAllProductSlice";
import LinearProgressForBills from "./LinearProgressForBills";

const AddProductErrorDialogBox = ({ errorDialogBills, seterrorDialogOpen }) => {
  const progressRef = useRef([]);
  const inputFile = useRef(null);
  const goodsInput = useRef(null);
  const dispatch = useDispatch();
  const [progress, setProgress] = React.useState(0);
  const [pdf, setpdf] = useState();
  const [fileError, setfileError] = useState();
  const [sizeMb, setsizeMb] = useState();
  const [pdftype, setpdftype] = useState();
  const [pdfProgress, setPdfProgress] = useState(0);
  const [billsName, setbillsName] = useState([]);
  const [pdfs, setPdfs] = useState([]);
  const [files, setFiles] = useState([]);
  const [id, setId] = useState();
  const [attachError, setAttachError] = useState('');
  const [fileId, setFileId] = useState([]);


  const billsData = useSelector((state) => state.bills);
  const errorData = useSelector((state) => state.error);
  const getpdfs = errorData.dataError.map((item) => item);

  const [pdfRec, setPdfRec] = React.useState([]);

  // console.log(billsData && billsData?.Bills.map(val => val.fileId === fileId), "data")
  var pdfIndex = 0;




  const billsDataLenght = billsData.Bills.length - 1;
  //       const AttachGoodsClick = () => {
  //     // `current` points to the mounted file input element
  //     if (!pdf) {
  //       setProgress(0);
  //     }
  //     goodsInput.current.click();
  //   };

  const pdfDeleteFunc = (item) => {
    const otarget = document.getElementById("addBilError");
    otarget.classList.add("noDisplay");
    // setpdf();
    // setbillsName([])
    dispatch(deleteBills(item));
    // setProgress(0);
  };

  function generateUniqueId() {
    const timestamp = new Date().getTime();
    const randomNumber = Math.floor(Math.random() * 1000000);
    return `${timestamp}-${randomNumber}`;
  }

  const addGoodBill = (event) => {
    const files = event.target.files;
    // const file = [...files];
    // const newFiles = [...files];
    for (let i = 0; i < files.length; i++) {
      const file = event.target.files[i];
      const typeType = file.type.split("/")[1];
      var fileSize = (file.size / 1024 / 1024).toFixed(2);
      var newType = typeType.replace(/\s/g, "");

      if (file) {
        // setprogressState(true);
        setsizeMb(fileSize);
        setpdftype(typeType);

        const otarget = document.getElementById("addBilError");
        const Erortarget = document.getElementById("addGoodError");
        if (newType !== "pdf" && newType !== "png") {
          setfileError("File not supported");
          otarget.innerHTML = "File not supported";
          otarget.classList.remove("noDisplay");
          Erortarget.classList.add("noDisplay");
          return;
        } else {
          otarget.classList.add("noDisplay");
          Erortarget.classList.add("noDisplay");
          setpdf(file);
          setfileError(null);
        }
        if (fileSize > 2) {
          otarget.innerHTML = "File size exceeds 2MB. Try a smaller file";
          otarget.classList.remove("noDisplay");
          Erortarget.classList.add("noDisplay");

          return;
        } else {
          const newEntry = {
            index: pdfIndex,
            progress: 0,
            status: "progress"
          };
          const specificIndex = pdfIndex;
          pdfIndex = pdfIndex + 1;



          // setPdfProgress(pdfProgress => [...pdfProgress, newEntry]);
          progressRef.current = [...progressRef.current, newEntry];
          dispatch(uploadBill({ file, fileSize, typeType }));
          dispatch(addBills({ file, progressRef, specificIndex }))
            .unwrap()
            .then((res) => {
              if (res?.success) {
                let newBill = { path: res.uploadPath, status: "pending" };
                setbillsName(prevBillsName => [...prevBillsName, newBill]);
                setProgress(90);
              }

            })
            .catch((error) => {
              console.log(error);
            });
          setProgress(0);
          otarget.classList.add("noDisplay");
          Erortarget.classList.add("noDisplay");
          setpdf(null);
        }
      }

      // newFiles.push(file);
    }


  };

  // console.log(files, "PDFFFFFFFFFFFFFFFFFFS")
  const AttachGoodsClick = () => {



    // `current` points to the mounted file input element
    if (!pdf) {
      // setProgress(0);
    }
    goodsInput.current.click();
  };

  const handleSubmit = () => {
    if (billsData?.Bills.length < 1) {
      const otarget = document.getElementById("addGoodError");
      const billtarget = document.getElementById("addBilError");
      otarget.classList.remove("noDisplay");
      otarget.innerHTML = "Bill of goods is required";
      billtarget.classList.add("noDisplay");
    }

    const clearForm = () => {
      seterrorDialogOpen(false);
    };


    if (billsName.length > 0) {
      const id = errorData.dataError[0].id;
      // setId(id);


      const Bilss = [...billsName, ...pdfRec];


      dispatch(updateProduct({ bills: Bilss, id }))
        .unwrap()
        .then((res) => {
          if (res?.success) {
            dispatch(getAllProduct());
            clearForm();
          }
        })
        .catch((error) => {
          console.log(error);
        });;
    }
  };
  React.useEffect(() => {

    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 10
      );
    }, 1500);
    return () => {
      clearInterval(timer);
    };

  }, [setpdf,]);


  React.useEffect(() => {
    if (errorData.dataError.length > 0 && getpdfs[0].type === 'error') {
      setPdfs(getpdfs[0].bills);


      console.log(pdfRec, "CHECKPDFSSS");

    }
  }, [errorData]);

  React.useEffect(() => {
    setPdfRec(pdfs.filter(item => {

      if (item.status === 'pending') {
        return item;
      }
    }
    ));
  }, [pdfs]);



  return (
    <div className="flex flex-col gap-[16px] p-[20px]">
      <div>
        <p className="addProductErrorText">
          The bill of goods you provided doesn't match. Upload it again.
        </p>
      </div>

      {pdfs.length > 0 && pdfs && pdfs?.map((item, index) => (

        (item.status == "error" &&
          <div className="flex gap-[8px] items-center">


            <div className="flex justify-between">
              <div className="flex gap-[8px] items-center">
                <p>
                  <PdfIcon />
                </p>
                <p className="addProductErrorFileName">
                  {item.path ? item.path : item.path}
                </p>
                <p className="pdfSize">1.8 mb</p>
              </div>
            </div>
          </div>)

      ))}
      <div>
        <p
          onClick={AttachGoodsClick}
          className="addForm_label flex items-center cursor-pointer !justify-start gap-2    !font-semibold !text-[#5B5B5B] "
        >
          + Attach the bill of goods <InfoIcon />
          <input
            type="file"
            ref={goodsInput}
            // {...register("pdf")}
            name="pdf"
            // accept=""
            onChange={(e) => addGoodBill(e)}
            style={{ display: "none" }}
            multiple
          // error={errors.pdf}
          />
        </p>
        {billsData && billsData.Bills.length > 0 && (
          <div className="addProductformBillDivider"></div>
        )}

        {billsData &&
          billsData?.Bills.map((item, index, arr) => {
            return (
              <div className="mt-[32px]">
                {progressRef.current[index] &&
                  <LinearProgressForBills
                    billsDataLenght={billsDataLenght}
                    item={item}
                    i={index}
                    pdfProgress={progressRef.current[index].progress}
                    pdftype={pdftype}
                  />
                }

              </div>
            );
          })}
        <p
          className="error_messge pl-[1px]  noDisplay"
          id="addGoodError"
        ></p>
        <p className="error_messge  noDisplay" id="addBilError"></p>
      </div>
      <div className="w-full flex justify-end">
        <ThemeAddProductErrorDialogProcessButton
          onClick={() => handleSubmit()}
          fullWidth
          variant="contained"
          type="submit"
        >
          Procceed
        </ThemeAddProductErrorDialogProcessButton>
      </div>
    </div>
  );
};

export default AddProductErrorDialogBox;
