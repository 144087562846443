import React, { useEffect, useState } from "react";
import {
  ThemeAddProductErrorDialogProcessButton,
  ThemeConfirmationError,
} from "../muiTheme/AddProductErrordialog";
import { changeInput, inputFocus } from "../muiTheme/OnFilledBorderStyle";
import { ThemeTextField } from "../muiTheme/Theme";
import { ReactComponent as PdfIcon } from "../image/svg/AddProductErrorDialogIcon.svg";
import { ReactComponent as DownloadIcon } from "../image/svg/DownloadIcon.svg";
import style from "../css/style.css";
import { useSelect } from "@mui/base";
import { useDispatch, useSelector } from "react-redux";
import { BaseUrl } from "../BaseURL/BaseUrl";
import { updateProduct } from "../redux/Slices/UpdateProductSlice";
import { getAllProduct } from "../redux/Slices/GetAllProductSlice";

const AddProductConfirmationError = (props) => {
  const [isPdf, setIsPdf] = React.useState(false);
  const [urls, setUrls] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [errors, setErrors] = useState([]);
  const errorData = useSelector((state) => state.error);
  const getPhotos = errorData.dataError.map((item) => item);



  const dispatch = useDispatch();


  const validateUrl = (url) => {
    // Regular expression to check for a valid URL format
    const urlRegex = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // IP address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return url.trim() !== '' && urlRegex.test(url.trim());
  };

  const handleInputChange = (index, event) => {
    const newUrls = [...urls];
    newUrls[index].url = event.target.value;
    setUrls(newUrls);

    const newErrors = [...errors];
    if (!validateUrl(event.target.value)) {
      newErrors[index] = 'Please enter a valid URL';
    } else {
      newErrors[index] = '';
    }
    setErrors(newErrors);
  };



  const handleSubmit = () => {
    const newErrors = urls.map((value) => {
      if (!validateUrl(value.url)) {
        return 'Please enter a valid URL';
      }
      return '';
    });
    setErrors(newErrors);

    const clearForm = () => {
      setErrors([]);
      setUrls([]);
      props.setConfirmationErrorOpen(false);
    };

    if (newErrors.every((error) => error === '') && urls.every((url) => url !== '')) {
      // clearForm()
      var urlss = [];

      for (var i = 0; i < urls.length; i++) {
        urlss.push({ url: urls[i].url, status: "pending" });
      }
      dispatch(updateProduct({ url: urlss, id: errorData.dataError[0].id }))
        .unwrap()
        .then((res) => {
          if (res?.success) {
            dispatch(getAllProduct());
            clearForm();
          }
        })
        .catch((error) => {
          console.log(error);
        });;
    }
  };

  const downloadPhoto = (data) => {
    // const url = document.getElementById("certificatePhoto").getAttribute("src");
    // const url = `${BaseUrl}/public/billsFiles/${newData[0].id}`;
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = "file.pdf";
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);

    const url = `${BaseUrl}/public/productImages/${data}`;
    const a = document.createElement("a");
    a.href = url;
    a.download = "Certificate.png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

  };

  React.useEffect(() => {
    if (errorData.dataError.length > 0 && getPhotos[0].type === 'error') {

      if (errorData.dataError[0].url != "undefined") {
        const urls = JSON.parse(errorData.dataError[0].url);

        setUrls(urls);
      }
      const photos = getPhotos[0].photo;
      setPhotos(photos);

    }
  }, [errorData]);


  const data =
    "alibaba.com/product-detail/Tv-Smart-Tv-Full-HD-Televisions_1600110452017.html?spm=a2700.7735675.topad_classic.d_title.3b615fa14jRr1B";
  return (
    <div>
      <div className="flex flex-col gap-[16px] p-[20px]">
        <div>
          <p className="addProductErrorText">
            The URL you provided doesn't comply.
            <br /> Upload photos again or try another link.
          </p>
        </div>

        {console.log(urls, "URLS =>")}
        {urls.length > 0 && urls.map((value, index) => (
          <div className={` ${value.status == "error" ? "" : "displpayNone"}`}>
            <p p className="AddProducturlLabel" > URL of your product</p>
            <ThemeConfirmationError
              autoFocus={"true"}
              fullWidth
              onChange={(e) => handleInputChange(index, e)}
              defaultValue={value.url}
              name="URL"
              placeholder="URL"
              onFocus={(event) => inputFocus(event)}
              onBlur={(event) => changeInput(event)}
              error={errors[index]}
            />

            <p className="error_messge">{errors[index]}</p>
          </div>
        ))}

        <div>
          <p className="AddProductErrorDownloadPhotoLabel pt-[20px] pb-[16px]">
            You can still dowload the photos{" "}
          </p>

          {photos?.map((item, index) => (
            <div className="flex justify-between">
              <div className="flex gap-[8px] items-center">
                <p>
                  <PdfIcon />
                </p>
                <p className="addProductErrorFileName">
                  {item == index[0] ? item : item}
                </p>
                <p className="pdfSize">1.8 mb</p>
              </div>
              <p>
                <DownloadIcon
                  onClick={() => downloadPhoto(item == index[0] ? item : item)}
                  className=" cursor-pointer  hover:bg-[#F5F5F5]"
                />
              </p>
            </div>
          ))}
        </div>

        <div className="w-full flex justify-end">
          <ThemeAddProductErrorDialogProcessButton
            onClick={() => handleSubmit()}
            fullWidth
            variant="contained"
            type="submit"
          >
            Procceed
          </ThemeAddProductErrorDialogProcessButton>
        </div>
      </div>
    </div >
  );
};

export default AddProductConfirmationError;
