import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { ThemeButton, ThemeDialogTitle, ThemeTextField } from "../muiTheme/Theme";
import { inputFocus, changeInput } from "../muiTheme/OnFilledBorderStyle";
import { ReactComponent as CrossIcon } from "../image/svg/CrossIcon.svg";
import { Button } from "@mui/material";
import CustomButton from "./CustomButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { addUser } from "../redux/Slices/AddUserAdminSlice";
import { getAllUsersAdmin } from "../redux/Slices/getAllUsersAdminSlice";


const schema = yup.object().shape({
    name: yup
        .string()
        .required("User Name is required"),
    email: yup.string().required("Email is required").email("Invalid email"),
    date: yup.string().required("Date is required"),
});


const FormModal = ({ handleClose, open, ...props }) => {
    const [radioOption, setRadioOption] = useState(null);
    const [formData, setFormData] = useState({});
    const [clickKValue, setclickkValue] = useState(false);
    const [email, setEmail] = useState();
    const [userName, setUserName] = useState();
    const [date, setDate] = useState();
    const [passwordError, setpasswordError] = useState(null);
    const [errorType, seterrorType] = useState(null);
    const [emailError, setemailError] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [dateError, setDateError] = useState(null);
    const [radioError, setRadioError] = useState(null);
    const [typeError, setTypeError] = useState("");
    const [submitClicked, setSubmitClicked] = useState(false);

    const dispatch = useDispatch();

    const handleDataFromChild = (data) => {
        if (data) {
            setRadioOption(data);
        } else if (!data) {
            setRadioOption(null);

        }
    };



    const handleCClickShowPassword = () => {
        setclickkValue(!clickKValue);
    };
    const {
        register,
        handleSubmit,
        reset,
        watch,
        getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const clearForm = () => {
        reset();
        handleClose();
    };

    useEffect(() => {
        return () => {
            if (submitClicked && !radioOption) {
                setRadioError("please select at least one option.");
            }
        };
    }, [submitClicked]);




    const submitForm = (data) => {
        setSubmitClicked(true);
        if (radioOption) {
            setRadioError(null);
            const formData = {
                email: data.email,
                userType: radioOption,
                userName: data.name,
                started: data.date
            };
            dispatch(addUser(formData))
                .unwrap()
                .then((res) => {
                    if (res?.success == true) {
                        clearForm();
                        dispatch(getAllUsersAdmin());
                    } else if (res?.success == false) {
                        setTypeError(res);
                    }
                })
                .catch((error) => {
                    console.log(error, "ERROR");
                });;
        }

    };

    const loginEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const handleUserName = (e) => {
        setUserName(e.target.value);
    };
    const handleDateChange = (e) => {
        setDate(e.target.value);
    };
    const dialogopenFocus = () => {

    };

    useEffect(() => {

    }, [handleDataFromChild]);

    return (
        <div>
            <form onSubmit={handleSubmit(submitForm)}>
                <div className="mainDiv ">
                    <div className="addProductFormBox !mt-0 !m-0 !py-6  !px-5 w-full sm:!w-[440px]  ">
                        <div className="singleTextBoxDiv !w-full">
                            <p className="text_label">User name</p>
                            <ThemeTextField
                                // size="small"
                                id="productNameFocusClass"
                                fullWidth
                                name="name"
                                placeholder="Write user name"
                                {...register("name", {
                                    onChange: (e) => handleUserName(e),
                                })}
                                onFocus={(event) => inputFocus(event)}
                                onBlur={(event) => changeInput(event)}
                                error={errors.name}
                            />
                            {errors.name || typeError?.type == "name" ? (
                                <p className="error_messge">{errors.name?.message || typeError?.message}</p>
                            ) : (
                                <p className="error_messge">
                                    {errorType == "name" && nameError}
                                </p>
                            )}
                        </div>
                        <div className="singleTextBoxDiv !w-full">
                            <p className="text_label">User Email</p>
                            <ThemeTextField
                                // autoFocus={"true"}
                                name="email"
                                placeholder="Write user email"
                                type={"text"}
                                size="small"
                                fullWidth
                                // defaultValue={data}
                                // sx={textFieldStyle}
                                {...register("email", {
                                    onChange: (e) => loginEmailChange(e),
                                })}
                                onBlur={(event) => changeInput(event)}
                                onFocus={(event) => inputFocus(event)}
                                error={errors.email}
                            />
                            {errors.email || typeError?.type == "email" ? (
                                <p className="error_messge">{errors.email?.message || typeError?.message}</p>
                            ) : (
                                <p className="error_messge">
                                    {errorType == "email" && emailError}
                                </p>
                            )}
                        </div>
                        <div className="singleTextBoxDiv !w-full" name="radio">
                            <p className="text_label">User Position</p>
                            <div className="mt-3">
                                <CustomButton options={['Designer', 'Technical']} onDataFromChild={handleDataFromChild} />
                            </div>
                            {submitClicked && !radioOption ? (
                                <p className="error_messge">{"Please select one of the options"}</p>
                            ) : (
                                <p className="error_messge">
                                    {" "}
                                </p>
                            )}
                        </div>
                        <div className="singleTextBoxDiv !w-full">
                            <p className="text_label">Started to Work</p>
                            <ThemeTextField
                                id="Started"
                                // autoFocus={"true"}
                                name="date"
                                // placeholder="Write user email"
                                type="date"
                                size="small"
                                fullWidth
                                // defaultValue={data}
                                // sx={textFieldStyle}
                                {...register("date", {
                                    onChange: (e) => handleDateChange(e),
                                })}
                                onBlur={(event) => changeInput(event)}
                                onFocus={(event) => inputFocus(event)}
                                error={errors.date}
                            />
                            {errors.date ? (
                                <p className="error_messge">{errors.date?.message}</p>
                            ) : (
                                <p className="error_messge">
                                    {errorType == "date" && dateError}
                                </p>
                            )}
                        </div>
                        <div className="w-full mt-[150px] sm:mt-[16px] addproductMobilePosition">
                            <ThemeButton
                                // onClick={() => formGoActive(true)}
                                onClick={() => setSubmitClicked(true)}
                                type="submit"
                                fullWidth
                                variant="contained"
                            >
                                Add User
                            </ThemeButton>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FormModal;