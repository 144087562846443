import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const AdminProRoutes = (props) => {
 const token = localStorage.getItem("AdminToken");
 const tToken = localStorage.getItem("TechnicalToken");
 const dToken = localStorage.getItem("DesignerToken");
 // console.log(token)
 if (token) {
  // const items = JSON.parse(token);
  return <Navigate to={'/admin-panel'} />;
 } else if (tToken) {
  return <Navigate to={'/my-tasks-products'} />;
 } else if (dToken) {
  return <Navigate to={'/my-tasks'} />;
 } else {
  return <Outlet />;
 }
};

export default AdminProRoutes;