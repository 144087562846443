import { createSlice } from '@reduxjs/toolkit';

const UpdataDataSlice = createSlice({
 name: 'updatedataslice',
 initialState: {
  updateData: false, 
 },
 reducers: {
    setupdateData: (state, action) => {
   state.updateData = action.payload; 
  },
 },
});

export const { setupdateData } = UpdataDataSlice.actions;
export default UpdataDataSlice.reducer;
