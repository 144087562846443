import React from 'react';
import { Navigate, Outlet } from "react-router-dom";


function DesignerPubRoutes() {
 const token = JSON.parse(localStorage.getItem("DesignerToken"));
 if (!token) {
  const items = JSON.parse(token);
  return <Navigate to={"/Staff-Login"} />;
 }
 return <Outlet />;
};

export default DesignerPubRoutes;