import React, { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import Box from "@mui/material/Box";
import { ReactComponent as WarningAlertIcon } from "../image/svg/WarningAlertIcon.svg";
import { ReactComponent as UploadFileIcon } from "../image/svg/UploadFileIcon.svg";
import { ReactComponent as CrossAlertIcon } from "../image/svg/CrossAlertIcon.svg";
import { ReactComponent as DownloadFileIcon } from "../image/svg/DownloadFileIcon.svg";
import { ReactComponent as DownloadIconWhite } from "../image/svg/DownloadIconWhite.svg";
import { ReactComponent as DownloadIconGray } from "../image/svg/DownloadIconGray.svg";
import { ReactComponent as UploadIcon } from "../image/svg/UploadIcon.svg";
import { BaseUrl } from "../BaseURL/BaseUrl";
import ProductsCards from "../components/ProductsCards";
import "../css/MyTasks.css";
import { uploadPhoto } from "../redux/Slices/UploadPhotoProductSlice";
import { addPhoto } from "../redux/Slices/AddPhotoSlice";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import FileUploadSharpIcon from "@mui/icons-material/FileUploadSharp";
import { deleteAll } from "../redux/Slices/GoodsBillsSlice";
import { deleteAllPhoto } from "../redux/Slices/UploadPhotoProductSlice";
import { ReactComponent as CrossIcon } from "../image/svg/CrossIcon.svg";
import DownloadSharpIcon from "@mui/icons-material/DownloadSharp";
import {
  ThemeTextField,
  ThemeDialogContent,
  ThemeDialogTitle,
  ThemeProductButtton,
  ThemeDialog,
} from "../muiTheme/Theme";
import noProduct from "../image/NoProductLogo.png";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { useNavigate } from "react-router-dom";
import { deletePhoto } from "../redux/Slices/UploadPhotoProductSlice";
import { deleteProductPhoto } from "../redux/Slices/ProductPhotoDeleteSlice";
import UploadPhoto from "../components/UploadPhotos";
import TopBarSimple from "../components/TopBarSimple";
import { GetShowPhotosWithReviews } from "../redux/Slices/GetShowPhotosWithReviewsSlice";
import { resetDownloaded, setDownloaded } from "../redux/Slices/onSendConfirmationSlice";


const MyTasks = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [arr, setArr] = useState([1]);
  const [show, setShow] = useState(false);
  const [reviewItems, setReviewItems] = useState([]);
  const [hoverIndex, setHoverIndex] = React.useState(-1);
  const [hoverShow, setHoverShow] = useState(false);
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const [DownloadPhotoOpen, setDownloadPhotoOpen] = React.useState(false);
  const [downloadPhoto, setdownloadPhoto] = useState();
  // const [downloaded, setDownloaded] = useState(false);
  const [value, setvalue] = useState({});
  const [alert, setAlert] = useState(true);
  const productData2 = useSelector((state) => state.allProduct.data.allProduct);
  var imagesAdded = [];
  var formGoActiv = false;
  var activImageIndex;
  const allPhotosWithReviews = useSelector(
    (state) => state.allPhotosWithReviews.data
  );
  const allProd = allPhotosWithReviews?.map((val) => val.photos);
  const downloaded = useSelector((state) => state.parent.downloaded);

  const handleDownloadChange = (value) => {
    dispatch(setDownloaded(value)); // dispatch the action to update the download state
  };

  const extractStatus = (data) => {
    const statuses = [];

    data.forEach((subArray) => {
      subArray.forEach((obj) => {
        if (obj.status) {
          statuses.push(obj);
        }
      });
    });

    return statuses;
  };

  const extractPhotos = (data) => {
    const photos = [];

    data.forEach((subArray) => {
      subArray.photos.forEach((obj) => {
        if (obj.path) {
          photos.push({ path: obj.path, status: obj.status, type: obj.type, proId: subArray.proId });
        }
      });
    });
    return photos;
  };
  if (allProd) {
    const photosArray = extractPhotos(allPhotosWithReviews);
    var reviewStatus = photosArray.filter(item => item.status === 'review');
  }

  console.log(reviewStatus, "Review Products")

  const token = localStorage.getItem("DesignerToken");

  const handleClickOpen = (event, pic) => {
    setvalue(pic);
    setOpen(true);
  };
  const handleClose = () => {
    dispatch(deleteAll());
    // setCloseAddProduct(false);
    setOpen(false);
  };
  const closeProductDialogBox = () => {
    handleClose();
    dispatch(deleteAllPhoto());
  };

  const handleCrossClick = () => {
    setAlert(false);
  };

  const handleDownload = (event, pic) => {
    const link = document.createElement('a');
    link.href = `${BaseUrl}/public/productImages/${pic.path}`;


    // Set the download attribute based on the file name
    const fileName = pic.path; // Set the desired file name
    link.setAttribute('download', fileName);

    // Convert the image to a data URL
    fetch(link.href)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        link.href = url;

        // Simulate a click on the link to trigger the download
        link.click();

        // Clean up by revoking the object URL
        URL.revokeObjectURL(url);
      });
    // setDownloaded(true);
    handleDownloadChange(true);

  };

  useEffect(() => {
    dispatch(GetShowPhotosWithReviews());
  }, []);

  return (
    <div>
      <div className={`active ${show && "navclass"}`}>
        <TopBarSimple token={token} />
      </div>
      <div className=" px-[42px]  ">
        <div className=" d-flex flex-column pt-[64px] sm:mt-[32px] justify-between">
          <div
            // onClick={() => seterrorDialogOpen(true)}
            className="text-[#2C2C2C] hidden sm:block text-[28px] font-semibold"
          >
            My Tasks
          </div>
          {reviewStatus.length===0 ? (<Box
            display={"grid"}
            justifyContent={"center"}
            justifyItems={"center"}
            alignItems={"center"}
            // border={3}
            pt={"210px"}
            gap={"20px"}
          >
            <div>
              <img className="w-[86px] h-[80px]" src={noProduct} alt="" />
            </div>
            <p className="text-[#5B5B5B] font-medium text-[16px] md:text-[24px] font-weight: 600 leading-6">
            No available tasks
            </p>
            <p className="text-[#9D9D9D] font-medium text-[16px] md:text-[24px] font-weight: 600 leading-6">
            You’ll see products as soon as they becomes available.
            </p>
          </Box>) : (<>
            <div className="my-5">
            {alert &&
              <Box className="warningBox">
                <div className="flex justify-between">
                  <div className="flex">
                    <div className="mx-2">
                      <WarningAlertIcon />
                    </div>
                    <div>
                      <h4>
                        <b className="text-[#A26100]">Pay Attention</b>
                      </h4>
                      <p>
                        You can download only one photo at once. Once you download
                        the photo and photoshop it, you need to upload a new photo
                        and send it to a client
                      </p>
                    </div>
                  </div>
                  <div className="cursor-pointer" onClick={() => handleCrossClick()}>
                    <CrossAlertIcon />
                  </div>
                </div>
              </Box>
            }

          </div>
          <div className="w-full flex justify-start g-5">
            <div className="container-fluid">
              <div className="grid grid-cols-2 gap-8 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-8">

                {
                  (reviewStatus && reviewStatus.map((pic, index) =>
                    <div
                      // onMouseEnter={() => setHoverIndex(index)}
                      // onMouseLeave={() => setHoverIndex(-1)}
                      key={index}
                      className="relative addMyTaskPhotoDiv actCertImg"
                    >
                      <input type="hidden" value={pic.proId} />
                      <span className="  ">
                        {" "}
                        <img
                          className="addMyTasksImageTag absolute max-w-full max-h-full "
                          src={`${BaseUrl}/public/productImages/${pic.path}`}
                          alt={`Picture ${index}`}
                        />
                      </span>
                      {
                        true && index === 0 ? (
                          downloaded === true ?
                            (
                              <span className="imageHoverPro">
                                <div onClick={(event) => handleClickOpen(event, pic)} className="mytasksimagedownload cursor-pointer">
                                  <div className="iconbox">
                                    <UploadIcon />
                                    <p>Upload Photo</p>
                                  </div>
                                </div>
                              </span>
                            )
                            :
                            (
                              <span className="imageHoverPro">
                                <div onClick={(event) => handleDownload(event, pic)} className="mytasksimagedownload cursor-pointer">
                                  <div className="iconbox">
                                    <DownloadIconWhite />
                                    <p>Download</p>
                                  </div>
                                </div>
                              </span>
                            )

                        ) : (
                          <span className="imageHoverPro">
                            <div className="mytasksimagedownload cursor-pointer">
                              <div className="iconbox">
                                <DownloadIconGray />
                                <p className="text-gray-600">Download</p>
                              </div>
                            </div>
                          </span>)
                      }

                    </div>)

                  )
                }

                <ThemeDialog
                  open={open}
                  scroll="paper"
                  keepMounted
                  // onClose={handleClose}
                  aria-describedby="alert-dialog-slide-description"
                >
                  <ThemeDialogTitle>
                    <div className="flex justify-between rounded-[8px 8px 0px 0px]  items-center py-[12px] pl-[20px] pr-[28px] ">
                      <span
                        onClick={() => handleClose()}
                        className="font-bold text-[16px] block sm:hidden text-[#31A246]"
                      >
                        Back
                      </span>
                      <span
                        className="font-medium text-[16px] text-[#707070]"
                        style={{ fontWeight: "bold" }}
                      >
                        Upload Photo
                      </span>
                      <div>
                        <span
                          onClick={closeProductDialogBox}
                          className="w-[14px] cursor-pointer hidden sm:block"
                        >
                          <CrossIcon className="goBackIcon cursor-pointer hover:bg-[#F5F5F5]" />
                        </span>
                      </div>
                    </div>
                  </ThemeDialogTitle>
                  <ThemeDialogContent>
                    <UploadPhoto handleClose={handleClose} open={open} value={value} closeProductDialogBox={closeProductDialogBox} />
                  </ThemeDialogContent>
                </ThemeDialog>



                {/* {arr.map((item) => {
                  return (
                    <div className="w-full flex justify-start col-2">
                      <span
                        className="mytasksimageupload"
                        onClick={handleClickOpen}
                      >
                        <div className="iconbox gap-[8px]">
                          <UploadFileIcon />
                          <p>Upload</p>
                        </div>
                      </span>
                      {/* {!photo ? (
                        <span
                          className="mytasksimageupload"
                          onClick={() => onButtonClick(1)}
                        >
                          <div className="iconbox">
                            <FileUploadSharpIcon />
                            <p>Upload</p>
                          </div>
                        </span>
                      ) : (
                        <div
                          onMouseEnter={() => sethoverShow(true)}
                          onMouseLeave={(e) => sethoverShow(false)}
                          className="relative addMyTaskPhotoDiv actCertImg"
                        >
                          <span className="  ">
                            {" "}
                            <img
                              className="addMyTasksImageTag absolute "
                              src={photo}
                              alt=""
                            />
                          </span>
                          {hoverShow && (
                            <span className="imageHoverPro">
                              <div className=" mytasksimagedownload  cursor-pointer ">
                                <div className="iconbox">
                                  <DownloadSharpIcon />
                                  <p>Download</p>
                                </div>
                              </div>
                            </span>
                          )}
                        </div>
                      )} }
                
              </div>
              );
                })} */}
              </div>
            </div>
          </div></>)}
          
        </div>
      </div>
    </div >
  );
};

export default MyTasks;