import { Routes, Route, BrowserRouter } from "react-router-dom";
import AddColleague from "./components/AddColleague";
import Example from "./components/AddColleagueTextField";
import AddProductDialogBox from "./components/AddProductErrorDialogBox";
import CompanyInfo from "./components/CompanyInfo";
import Raf from "./components/Raf";
import Certificate from "./pages/Certificate";
import EmailSendResetPass from "./pages/EmailSendResetPass";
import Home from "./pages/Home";
import LogIn from "./pages/LogIn";
import Product from "./pages/Product";
import ProfileView from "./pages/ProfileView";
import ResetPassword from "./pages/ResetPassword";
import SignUp from "./pages/SignUp";
import SingleProductDetail from "./pages/SingleProductDetail";
import TransfersList from "./pages/TransfersList";
import UserSignUp from "./pages/UserSignUp";
import React from "react";
import PhoneNumberTextBox from "./components/PhoneNumberTextBox";
import ProRoutes from "./ProtectedRoutes/ProRoute";
import PublicRoute from "./ProtectedRoutes/PublicRoutes";
import AdminProRoute from "./ProtectedRoutes/AdminProRoutes";
import TransferDetial from "./pages/TransferDetail";
import InvitationVerification from "./pages/InvitationVerification";
import PasswordVerification from "./pages/PasswordVerification";
import ResetPassword2nd from "./pages/ResetPassword2ndPage";
import StaffLogin from "./pages/StaffLogin";
import MyTasks from "./pages/MyTasks";
import MyTasksWithProducts from "./pages/MyTasksWithProducts";
import ProductPageCLink from "./pages/ProductPageCLink";
import AdminPanel from "./pages/AdminPanel";
import AdminPubRoute from "./ProtectedRoutes/AdminPubRoute";
import AdminProRoutes from "./ProtectedRoutes/AdminProRoutes";
import ResetAccessCode from "./pages/ResetAccessCode";
import DesignerPubRoutes from "./ProtectedRoutes/DesignerPubRoutes";
import TechnicalPubRoute from "./ProtectedRoutes/TechnicalPubRoutes";
import UserViewMore from "./pages/UserViewMore";
function App() {
  return (
    <BrowserRouter >
      <Routes >
        <Route element={<ProRoutes />}>
          <Route path="/products" element={<Product />} />
          <Route path="/raf" element={<Raf />} />
          <Route path="/transferlist" element={<TransfersList />} />
          <Route path="/companyinfo" element={<CompanyInfo />} />
          <Route path="/transferdetial/:data" element={<TransferDetial />} />
          <Route
            path="/singleproductdetail/:id"
            element={<SingleProductDetail />}
          />
          <Route path="/profileview" element={<ProfileView />} />
          <Route path="/addcolleague" element={<AddColleague />} />
          <Route path="/certificate" element={<Certificate />} />
          <Route
            path="/addproductbialogbox"
            element={<AddProductDialogBox />}
          />
          <Route path="/phone" element={<PhoneNumberTextBox />} />
          <Route path="/test" element={<Example />} />
        </Route>
        <Route element={<PublicRoute />}>
          <Route path="/" element={<Home />} />
          {/* <Route path="/onestepsignup" element={<OneStepSignUp />} /> */}
          <Route path="/emailsend" element={<EmailSendResetPass />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/resetpassword2nd/:id" element={<ResetPassword2nd />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/usersignup/:id/:companyId" element={<UserSignUp />} />
          <Route path="/varification/:token" element={<InvitationVerification />} />
          <Route path="/passwordvarification/:token" element={<PasswordVerification />} />



          <Route path="/product-page-link" element={<ProductPageCLink />} />
        </Route>
        <Route element={<><AdminProRoutes /></>}>
          <Route path="/staff-login" element={<StaffLogin />} />
          <Route path="/forgotcode" element={<ResetAccessCode />} />
        </Route>
        <Route element={<AdminPubRoute />}>
          <Route path="/admin-panel" element={<AdminPanel />} />
          <Route path="/UserViewMore" element={<UserViewMore />} />
        </Route>
        <Route element={<TechnicalPubRoute />}>
          {/* Technical  */}
          <Route path="/my-tasks-products" element={<MyTasksWithProducts />} />
        </Route>
        <Route element={<DesignerPubRoutes />}>
          {/* designer  */}
          <Route path="/my-tasks" element={<MyTasks />} />
        </Route>
      </Routes>
    </BrowserRouter>

  );
}

export default App;
