import React, { useState } from "react";
import "../css/UploadPhotos.css";
import { ThemeTextField, ThemeButton } from "../muiTheme/Theme";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useRef } from "react";
import "../css/SmallPopUp.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { ReactComponent as CrossIcon } from "../image/svg/BillCrossIcon.svg";
import ClearIcon from '@mui/icons-material/Clear';
var formGoActiv = false;
const SmallPopUp = ({ open, handleClose, text }) => {
  const dialogopenFocus = () => {
    const targett = document.getElementById("productNameFocusClass");
    if (targett) {
      targett.focus();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dialogopenFocus();
  }, [open]);

  useEffect(() => {
    formGoActiv = false;
  }, [handleClose]);

  return (
    <div>
      <div className="mainDiv">
        <div className="smallpopupmodal flex justify-between items-center gap-[20px]">
          <TaskAltIcon />
          <h1>{text}</h1>
          <ClearIcon
            onClick={() => handleClose()}
            // className="cursor-pointer"
            // style={{ width: "20px", height: "20px",color: "#174f13" }}
          />
        </div>
      </div>
    </div>
  );
};

export default SmallPopUp;
