import React from 'react';
import { deleteUser } from "../redux/Slices/DeleteUserAdminSlice";
import { useDispatch } from "react-redux";
import { getAllUsersAdmin } from "../redux/Slices/getAllUsersAdminSlice";


function DeleteUserModalDialog({ closeDeleteUserDialogBox, selectedItem }) {

  const dispatch = useDispatch();

  const handleDeleteClick = (e) => {
    //Api call delete
    dispatch(deleteUser(selectedItem._id))
      .unwrap()
      .then((res) => {
        if (res?.success == true) {
          closeDeleteUserDialogBox();
          dispatch(getAllUsersAdmin());
        }
      })
      .catch((error) => {
        console.log(error, "ERROR");
      });;
  };



  const handleCancelClick = () => {
    closeDeleteUserDialogBox();
  };

  return (
    <div className="pb-5 pr-5">
      <div className="flex flex-col p-7">
        <span className="font-sans">User won't be able to access their workspace <br />
          with tasks. Their tasks will be assigned to the <br />
          user with the least amount of tasks.  </span>
        <span className="font-sans font-bold">Are you sure you want to delete the user?</span>
      </div>


      <div className="flex flex-row justify-end items-center gap-5">
        <button onClick={handleCancelClick} className="border border-gray-400 font-bold text-gray-400 bg-transparent hover:bg-gray-100 hover:text-gray-600 rounded px-4 h-10 w-36">
          Cancel
        </button>

        <button onClick={(e) => handleDeleteClick(e)} className="font-bold bg-red-500 hover:bg-red-600 text-white rounded px-4 h-10 w-36">
          Delete
        </button>
      </div>
    </div>

  );
}

export default DeleteUserModalDialog;