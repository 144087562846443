import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TopBar from "../components/TopBar";
import Button from "@mui/material/Button";
import { useNavigate, useHistory, useLocation } from "react-router-dom";
import "../css/MyTasksWithProducts.css";
import ConfirmtionModal from "../components/ConfirmtionModal";
import { ReactComponent as CrossIcon } from "../image/svg/BillCrossIcon.svg";
import { BaseUrl } from "../BaseURL/BaseUrl";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import noProduct from "../image/NoProductLogo.png";
import {
  ThemeTextField,
  ThemeDialogContent,
  ThemeDialogTitle,
  ThemeProductButtton,
  ThemeDialog,
  SmallPopUpDialog,
  ThemeButton,
} from "../muiTheme/Theme";
import SmallPopUp from "../components/SmallPopUp";
import TopBarSimple from "../components/TopBarSimple";
import { getProcessedProducts } from "../redux/Slices/GetProcessedProductsSlice";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

const MyTasksWithProducts = ({ open, handleClose }) => {
  const [show, setShow] = useState(false);
  const [confirm, setConfirm] = useState(true);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openModalLink, setOpenModalLink] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const token = JSON.parse(localStorage.getItem("TechnicalToken"));
  const decodedToken = jwt_decode(token);
  const uId = decodedToken._id;
  console.log(uId, "uId");
  const dispatch = useDispatch();
  const popUpCheck = useSelector((state) => state.ontechpopupslice.popUpOpen);
  const popUpCheckLink = useSelector(
    (state) => state.linkconfirmationslice.linkPopUpOpen
  );
  const upDateState = useSelector((state) => state.updatedataslice.updateData);
  console.log(upDateState, "upDateState");
  const products = useSelector((state) => state.processedProducts.data);
  console.log(products, "prodducrs");
  const closeModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (popUpCheck === true) {
      const timer = setTimeout(() => {
        setOpenModal(false);
      }, 3000);
      setOpenModal(true);
      return () => clearTimeout(timer);
    } else {
      setOpenModal(false);
    }
  }, [popUpCheck]);

  useEffect(() => {
    if (popUpCheckLink === true) {
      const timer = setTimeout(() => {
        setOpenModalLink(false);
      }, 3000);
      setOpenModalLink(true);
      return () => clearTimeout(timer);
    } else {
      setOpenModalLink(false);
    }
  }, [popUpCheckLink]);

  useEffect(() => {
    console.log("Shifted");
    dispatch(getProcessedProducts());
  }, [dispatch]);

  useEffect(() => {
    console.log("ppppp", products);
  }, []);

  return (
    <div>
      <div className={`active ${show && "navclass"}`}>
        <TopBarSimple token={token} />
      </div>

      {/* Bill of goods sent back to the customer */}
      <div>
        <SmallPopUpDialog
          open={openModal}
          scroll="paper"
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <ThemeDialogContent>
            <SmallPopUp
              text={"Bill of goods sent back to the customer"}
              handleClose={closeModal}
              open={openModal}
            />
          </ThemeDialogContent>
        </SmallPopUpDialog>
      </div>

      {/*Link confirmed*/}
      <div>
        <SmallPopUpDialog
          open={openModalLink}
          scroll="paper"
          keepMounted
          // onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <ThemeDialogContent>
            <SmallPopUp
              text={"Link confirmed"}
              handleClose={closeModal}
              open={openModalLink}
            />
          </ThemeDialogContent>
        </SmallPopUpDialog>
      </div>
      <div className=" px-[42px]  ">
        <div className=" d-flex flex-column pt-[64px] sm:mt-[32px] justify-between">
          <div className="text-[#2C2C2C] hidden sm:block text-[28px] font-semibold">
            My Tasks
          </div>
          {products?.processProducts && products?.processProducts.length === 0 ? (
            <Box
              display={"grid"}
              justifyContent={"center"}
              justifyItems={"center"}
              alignItems={"center"}
              // border={3}
              pt={"210px"}
              gap={"20px"}
            >
              <div>
                <img className="w-[86px] h-[80px]" src={noProduct} alt="" />
              </div>
              <p className="text-[#5B5B5B] font-medium text-[16px] md:text-[24px] font-weight: 600 leading-6">
                No available tasks
              </p>
              <p className="text-[#9D9D9D] font-medium text-[16px] md:text-[24px] font-weight: 600 leading-6">
                You’ll see products as soon as they becomes available.
              </p>
            </Box>
          ) : (
            <div className="mt-[36px]">
              <TableContainer>
                <Table cellSpacing={10}>
                  <TableHead>
                    <TableRow className="top-row">
                      <TableCell className="table-head">Product Name</TableCell>
                      <TableCell align="left" className="table-head">
                        Image
                      </TableCell>
                      <TableCell align="left" className="table-head">
                        Serial Number
                      </TableCell>
                      <TableCell align="left" className="table-head">
                        Review Status
                      </TableCell>
                      <TableCell
                        align="right"
                        className="table-head"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products?.processProducts?.map((item) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        className="table-rows"
                        // key={item._id}
                      >
                        <TableCell component="th" scope="row">
                          {item.productName}
                        </TableCell>
                        <TableCell>
                          <img
                            src={
                              // item.productPhoto &&
                              // Array.isArray(item.productPhoto) &&
                              // JSON.parse(item.productPhoto).length > 0 &&
                              `${BaseUrl}/public/productImages/${
                                JSON.parse(item.productPhoto)[0]?.path
                              }`
                            }
                            alt={item.productName}
                            className="p-image"
                          />
                        </TableCell>
                        <TableCell align="left">{item.serialNumber}</TableCell>
                        <TableCell align="center">
                          {item.productUrl &&
                          JSON.parse(item.productUrl).some(
                            (Item) => Item.status == "pending"
                          ) ? (
                            <button
                              variant="contained"
                              className="button-table btn-confirm"
                            >
                              Confirmation link
                            </button>
                          ) : (
                            <button
                              variant="contained"
                              className="button-table btn-status"
                            >
                              Bill of goods
                            </button>
                          )}
                        </TableCell>
                        <TableCell align="right" className="review-text">
                          Review
                          <ArrowForwardIosIcon
                            className="arrow-icon"
                            onClick={() => {
                              setSelectedItem(
                                item === selectedItem ? null : item
                              );
                              navigate("/product-page-link", {
                                state: { ...item },
                              });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyTasksWithProducts;
