import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL/BaseUrl";
import { array } from "yup";
import { ArrowBackOutlined } from "@mui/icons-material";
const axios = require("axios");

export const addBills = createAsyncThunk("user/addBills", async (data) => {

  try {
    const token = JSON.parse(localStorage.getItem("token"));
    const state = data.progressRef;
    const index = data.specificIndex;

    const newState = state.current.find((item) => item.index == index);

    data = data.file;
    let fd = new FormData();
    fd.append("pdf", data);


    const config = {
      headers: { Authorization: "Bearer " + token },
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        newState.progress = percentCompleted;
        console.log(state.current[newState.index], newState, "NewState");
        // state.current.splice(newState.index, 1, newState);
        state.current[newState.index] = newState;
      }
    };

    const res = await axios.post(
      `${BaseUrl}/api/product/addbills`,
      fd,
      config,
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
});
export const addBillsSlice = createSlice({
  name: "bills",
  initialState: {
    data: [],
    loading: false,
    error: false,
    progress: [],
  },
  reducers: {},
  extraReducers: {
    [addBills.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [addBills.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [addBills.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export default addBillsSlice.reducer;
