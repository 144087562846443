import { React, useEffect, useState, useMemo } from "react";
import TopBarSimple from "../components/TopBarSimple";
import { ReactComponent as GreaterIcon } from "../image/svg/GreaterIcon.svg";
import { ReactComponent as TaskIcon } from "../image/svg/TaskIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAllTasks,
  selectLoadingTasks,
} from "../redux/Slices/GetAllTasksSlice";
import { useDispatch, useSelector } from "react-redux";

function UserViewMore() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { selectedItem } = location.state || {};
  const navigate = useNavigate();
  const todoTasks = useSelector((state) => state.alltasks?.todoTasks);
  const inProgressTasks = useSelector(
    (state) => state.alltasks?.inProgressTasks
  );
  const doneTasks = useSelector((state) => state.alltasks?.doneTasks);

  const isLoadingTasks = useSelector(selectLoadingTasks);
  const token = JSON.parse(localStorage.getItem("AdminToken"));
  const [allTodoTasks, setAllTodoTasks] = useState([]);
  const [allInProgressTasks, setAllInProgressTasks] = useState([]);
  const [allDoneTasks, setAllDoneTasks] = useState([]);
  const handleOnUserClick = (e) => {
    navigate(-1);
  };

  const userViewStyle = {
    height: "fit-content",
  };

  useEffect(() => {
    setAllInProgressTasks(inProgressTasks);
    return () => {
      setAllInProgressTasks();
    };
  }, [inProgressTasks]);

  useEffect(() => {
    setAllDoneTasks(doneTasks);
    return () => {
      setAllDoneTasks([]);
    };
  }, [doneTasks]);

  useEffect(() => {
    setAllTodoTasks(todoTasks);
    return () => {
      setAllTodoTasks([]);
    };
  }, [todoTasks]);

  useEffect(() => {
    dispatch(getAllTasks(selectedItem));
  }, []);

  return (
    <div className="setProjectBg">
      <div className="fixed z-10">
        <TopBarSimple token={token} />
      </div>

      <div className="h-screen mt-16">
        <div className="flex flex-col p-10">
          <div className="flex flex-row ">
            <span
              className="font-source-sans-pro font-semibold text-xs leading-4 text-gray-600 px-1 cursor-pointer"
              onClick={(e) => handleOnUserClick(e)}
            >
              Users
            </span>
            <div className="mt-[3px] ">
              <GreaterIcon />
            </div>
            <span className="font-source-sans-pro font-semibold text-xs leading-4 px-1">
              {selectedItem?.userName}
            </span>
          </div>

          <div className="flex flex-row justify-between p-3 border border-gray-200 rounded-lg mt-5 bg-white">
            <div>
              <span className="font-source-sans-pro font-semibold text-base leading-7">
                {selectedItem?.userName}
              </span>

              <div className="flex flex-row">
                <div className="flex flex-row">
                  <span className="font-source-sans-pro font-normal text-sm leading-5">
                    {selectedItem?.userType},<span className="ml-1"></span>
                  </span>
                  <span className="font-source-sans-pro font-normal text-sm leading-5">
                    {selectedItem?.started}
                  </span>
                </div>

                <div className="flex flex-row ml-5">
                  <span className="font-source-sans-pro font-semibold text-sm leading-5">
                    Contact Info
                  </span>
                  <span className="font-source-sans-pro font-normal text-sm leading-5 ml-2">
                    {selectedItem?.email}
                  </span>
                </div>

                <div className="flex flex-row ml-5">
                  <span className="font-source-sans-pro font-semibold text-sm leading-5">
                    Access Code
                  </span>
                  <div className="font-source-sans-pro font-normal text-sm leading-5 ml-2">
                    Es1223123
                  </div>
                </div>
              </div>
            </div>

            <div>
              <button
                className={`flex items-center w-20 justify-center pointer-events-none rounded-full ${
                  true ? "bg-green-100" : "bg-red-100"
                }`}
              >
                <span className="rounded-full h-2 w-2 bg-green-700 mr-2"></span>
                <span className="text-green-700">
                  {" "}
                  {`${true ? "Active" : "Inactive"} `}{" "}
                </span>
              </button>
            </div>
          </div>
        </div>

        {false ? (
          <div className="flex flex-col  justify-center items-center mt-10">
            <TaskIcon />
            <span className="font-source-sans-pro font-semibold text-base leading-6 mt-5">
              No task to display
            </span>
            <span className="font-source-sans-pro font-normal text-base leading-6 text-gray-600">
              You’ll see user's tasks as soon as they becomes available.
            </span>
          </div>
        ) : (
          <div className="flex px-10">
            <div
              className="box-border flex flex-col h-auto items-start p-4  bg-white border border-gray-300 rounded-md "
              style={userViewStyle}
            >
              <span className="font-source-sans-pro font-bold text-base leading-6">
                To do
              </span>
              {isLoadingTasks ? (
                <div className="w-48 bg-gray-50 border border-gray-100 rounded-lg p-2 mt-3">
                  <span className="font-source-sans-pro text-xs font-bold text-gray-600 leading-4 mt-3">
                    Loading...
                  </span>
                </div>
              ) : (
                <>
                  {allTodoTasks?.length === 0 ? (
                    <div className="w-48 bg-gray-50 border border-gray-100 rounded-lg p-2 mt-3">
                      <span className="font-source-sans-pro text-xs font-bold text-gray-600 leading-4 mt-3">
                        No Task Available
                      </span>
                    </div>
                  ) : (
                    <div>
                      {allTodoTasks?.map((item) => {
                        return (
                          <div className="w-48 bg-gray-50 border border-gray-100 rounded-lg p-2 mt-3">
                            <span className="font-rubik font-bold text-sm leading-4">
                              {item?.task?.taskTitle}
                            </span>
                            <div className="flex flex-col mt-2">
                              <div className="flex flex-row">
                                <span className="font-source-sans-pro text-xs font-bold text-gray-600 leading-4">
                                  Company:
                                </span>
                                <span className="font-source-sans-pro text-xs font-bold leading-4 ml-2">
                                  {item?.company?.companyName}
                                </span>
                              </div>

                              <div className="flex flex-row">
                                <span className="font-source-sans-pro text-xs font-bold text-gray-600 leading-4">
                                  Date Added:
                                </span>
                                <span className="font-source-sans-pro text-xs font-bold leading-4 ml-2">
                                  {new Date(
                                    item.company.createdAt
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
            <div
              className="box-border flex flex-col h-auto items-start p-4  bg-white border border-gray-300 rounded-md ml-8"
              style={userViewStyle}
            >
              <span className="font-source-sans-pro font-bold text-base leading-6">
                In Progress
              </span>
              {isLoadingTasks ? (
                <div className="w-48 bg-gray-50 border border-gray-100 rounded-lg p-2 mt-3">
                  <span className="font-source-sans-pro text-xs font-bold text-gray-600 leading-4 mt-3">
                    Loading...
                  </span>
                </div>
              ) : (
                <>
                  {allInProgressTasks?.length === 0 ? (
                    <div className="w-48 bg-gray-50 border border-gray-100 rounded-lg p-2 mt-3">
                      <span className="font-source-sans-pro text-xs font-bold text-gray-600 leading-4 mt-3">
                        No Task Available
                      </span>
                    </div>
                  ) : (
                    <div>
                      {allInProgressTasks.map((task) => {
                        return (
                          <div className="w-48 bg-gray-50 border border-gray-100 rounded-lg p-2 mt-3 ">
                            <span className="font-rubik font-bold text-sm leading-4">
                              {task.task.taskTitle}
                            </span>
                            <div className="flex flex-col mt-2">
                              <div className="flex flex-row">
                                <span className="font-source-sans-pro text-xs font-bold text-gray-600 leading-4">
                                  Company:
                                </span>
                                <span className="font-source-sans-pro text-xs font-bold leading-4 ml-2">
                                  {task.company.companyName}
                                </span>
                              </div>

                              <div className="flex flex-row">
                                <span className="font-source-sans-pro text-xs font-bold text-gray-600 leading-4">
                                  Date Added:
                                </span>
                                <span className="font-source-sans-pro text-xs font-bold leading-4 ml-2">
                                  {new Date(
                                    task.company.createdAt
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}{" "}
                </>
              )}
            </div>

            <div
              className="box-border flex flex-col h-auto items-start p-4  bg-white border border-gray-300 rounded-md ml-8"
              style={userViewStyle}
            >
              <span className="font-source-sans-pro font-bold text-base leading-6">
                Done
              </span>
              {isLoadingTasks ? (
                <div className="w-48 bg-gray-50 border border-gray-100 rounded-lg p-2 mt-3">
                  <span className="font-source-sans-pro text-xs font-bold text-gray-600 leading-4 mt-3">
                    Loading...
                  </span>
                </div>
              ) : (
                <>
                  {allDoneTasks?.length === 0 ? (
                    <div className="w-48 bg-gray-50 border border-gray-100 rounded-lg p-2 mt-3">
                      <span className="font-source-sans-pro text-xs font-bold text-gray-600 leading-4 mt-3">
                        No Task Available
                      </span>
                    </div>
                  ) : (
                    <div>
                      {allDoneTasks.map((item) => {
                        return (
                          <div className="w-48 bg-gray-50 border border-gray-100 rounded-lg p-2 mt-3 ">
                            <span className="font-rubik font-bold text-sm leading-4">
                              {item.task.taskTitle}
                            </span>
                            <div className="flex flex-col mt-2">
                              <div className="flex flex-row">
                                <span className="font-source-sans-pro text-xs font-bold text-gray-600 leading-4">
                                  Company:
                                </span>
                                <span className="font-source-sans-pro text-xs font-bold leading-4 ml-2">
                                  {item.company.companyName}
                                </span>
                              </div>

                              <div className="flex flex-row">
                                <span className="font-source-sans-pro text-xs font-bold text-gray-600 leading-4">
                                  Date Added:
                                </span>
                                <span className="font-source-sans-pro text-xs font-bold leading-4 ml-2">
                                  {new Date(
                                    item.company.createdAt
                                  ).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserViewMore;
