import React, { useState } from "react";
import { ReactComponent as ThreeVerticalDots } from "../image/svg/ThreeVertalDots.svg";
import { ReactComponent as ViewIcon } from "../image/svg/ViewIcon.svg";
import { ReactComponent as EditIcon } from "../image/svg/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../image/svg/DelIcon.svg";
import Button from "@mui/material/Button";
import EditUserModalForm from "../components/EditUserModalForm";
import DeleteUserModalDialog from "../components/DeleteUserModalDialog";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  ThemeDialog,
  ThemeDialogContent,
  ThemeDialogTitle,
} from "../muiTheme/Theme";
import { ReactComponent as CrossIcon } from "../image/svg/CrossIcon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetShowPhotosWithReviews } from "../redux/Slices/GetShowPhotosWithReviewsSlice";
import { getProcessedProducts } from "../redux/Slices/GetProcessedProductsSlice";
import {
  getTodoTasks,
  selectLoadingTodoTasks,
} from "../redux/Slices/GetTodoTasksSlice";

function UserView({ active, formData }) {
  // const [open, setOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState([]);
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [deleteUserOpen, setDeleteUserOpen] = useState(false);
  const [formDataa, setFormDataa] = useState(null);
  const [productsArr, setProductsArr] = useState([]);
  const [designerTodoTasks, setDesignerTodoTasks] = useState([]);
  const [technicalTodoTasks, setTechnicalTodoTasks] = useState([]);

  const designerTodoTasksArray = useSelector(
    (state) => state.todoTasks.designerTasksArrayLength
  );
  const technicalTodoTasksArray = useSelector(
    (state) => state.todoTasks.technicalTasksArrayLength
  );
  const loadingTodoTasks = useSelector(selectLoadingTodoTasks);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const allPhotosWithReviews = useSelector(
    (state) => state.allPhotosWithReviews.data
  );

  const toggleDropdown = (item, event) => {
    // setAnchorEl(event.currentTarget);
    setSelectedItem(item === selectedItem ? null : item);
    // setClickPosition({ x: event.clientX, y: event.clientY });
    setAnchorEl(event.currentTarget);
  };

  const handleFormSubmit = (data) => {
    setFormDataa(data);
  };

  const handleViewMore = (item, event) => {
    // setSelectedItem(item === selectedItem ? null : item);
    const selectedItem = { ...item };
    navigate("/UserViewMore", { state: { selectedItem } });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEditUserClose = () => {
    setEditUserOpen(false);
  };

  const handleDeleteUserClose = () => {
    setDeleteUserOpen(false);
  };

  const closeEditUserDialogBox = () => {
    handleEditUserClose();
  };

  const closeDeleteUserDialogBox = () => {
    handleDeleteUserClose();
  };

  const handleViewClick = (event) => {
    navigate("/UserViewMore", { state: { selectedItem } });
  };
  const handleEditClick = (event) => {
    setAnchorEl(null);
    setEditUserOpen(true);
  };
  const handleDeleteClick = (event) => {
    setAnchorEl(null);
    setDeleteUserOpen(true);
  };

  useEffect(() => {
    const userTypes = "Designer,Technical";
    dispatch(getTodoTasks(userTypes));
  }, [formData]);

  useEffect(() => {
    setDesignerTodoTasks(designerTodoTasksArray);
    setTechnicalTodoTasks(technicalTodoTasksArray);
  }, []);

  useEffect(() => {
    dispatch(getProcessedProducts());
    dispatch(GetShowPhotosWithReviews());
  }, []);


  return (
    <div className="h-screen bg-white border border-gray-200 ">
      <div className=" bg-gray-100 flex flex-row items-center justify-between px-4 py-2 border border-gray-200 ">
        <div className="flex-grow w-1/4">
          <p className="text-sm font-medium text-gray-900">Name</p>
        </div>
        <div className="flex-grow w-1/4">
          <p className="text-sm font-medium text-gray-900">Position</p>
        </div>
        <div className="flex-grow w-1/4">
          <p className="text-sm font-medium text-gray-900">User Status</p>
        </div>
        <div className="flex-grow w-1/6 justify-items-end">
          <p className="text-sm font-medium text-gray-900">Tasks</p>
        </div>
        <div>
          <button className="flex items-center justify-center w-8 h-8 text-gray-600  rounded-full hover:bg-gray-200 focus:outline-none ">
            <ThreeVerticalDots />
          </button>
        </div>
      </div>
      {formData?.map((value) => (
        <div className="">
          <div
            className={`flex flex-row items-center justify-between px-4 py-2 ${
              selectedItem === value && anchorEl ? "bg-green-100" : "  "
            }   border border-gray-200`}
          >
            <div className="flex-grow w-1/4 ">
              <p className="text-sm font-bold text-gray-900">
                {value.userName}
              </p>
              <p className="text-sm text-gray-500">{value.email}</p>
            </div>
            <div className="flex-grow w-1/4 ">
              <p className="text-sm font-bold text-gray-900">
                {value.userType}
              </p>
              <p className="text-sm text-gray-500">
                Joined on: {value.started}
              </p>
            </div>
            <div className="flex-grow w-1/6">
              <button
                className={`flex items-center w-20 justify-center pointer-events-none rounded-full ${
                  active ? "bg-green-100" : "bg-red-100"
                }`}
              >
                <span className="rounded-full h-2 w-2 bg-green-700 mr-2"></span>
                <span className="text-green-700">
                  {" "}
                  {`${active ? "Active" : "Inactive"} `}{" "}
                </span>
              </button>
              <p className="mt-1 text-sm text-gray-500">
                Activity: {value.started}
              </p>
            </div>
            <div className="flex-grow w-1/6 flex justify-center">
              <p className="text-sm font-bold text-gray-900">
                {loadingTodoTasks ? (
                  <span>Loading...</span>
                ) : value.userType == "Designer" ? (
                  designerTodoTasks
                ) : value.userType == "Technical" ? (
                  technicalTodoTasks
                ) : (
                  0
                )}
              </p>
            </div>

            <div className="flex-grow">
              <button
                onClick={(event) => handleViewMore(value, event)}
                className="px-2 py-1 text-sm text-gray-500 rounded-full hover:bg-gray-200 "
              >
                View More
              </button>
            </div>

            <div>
              <button
                onClick={(event) => toggleDropdown(value, event)}
                data-dropdown-toggle="dropdown"
                type="button"
                className="flex items-center justify-center w-8 h-8 text-gray-600  rounded-full hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
              >
                <ThreeVerticalDots />
              </button>
            </div>

            <div>
              {selectedItem === value && (
                <div>
                  <Menu
                    id="demo-positioned-menu"
                    className="drop-shadow-lg"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    disableScrollLock={true}
                    onClose={handleClose}
                    // onClick={handleOpen}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem
                      onClick={(event) => handleViewClick(event)}
                      className="relative"
                    >
                      <span className="mr-6 font-sans font-normal font-regular text-base leading-5">
                        View
                      </span>
                      <ViewIcon className="absolute right-0 mr-2" />
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => handleEditClick(event)}
                      className="relative"
                    >
                      <span className="mr-6 font-sans font-normal font-regular text-base leading-5">
                        Edit
                      </span>
                      <EditIcon className="absolute right-0 mr-2" />
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => handleDeleteClick(event)}
                      className="relative"
                    >
                      <span className="mr-6 font-sans font-normal font-regular text-base leading-5">
                        Delete
                      </span>
                      <DeleteIcon className="absolute right-0 mr-2" />
                    </MenuItem>
                  </Menu>

                  <ThemeDialog
                    open={editUserOpen}
                    scroll="paper"
                    keepMounted
                    disableScrollLock={true}
                    // onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <ThemeDialogTitle>
                      <div className="flex justify-between rounded-[8px 8px 0px 0px]  items-center py-[12px] pl-[20px] pr-[28px] ">
                        <span
                          onClick={() => handleEditUserClose()}
                          className="font-bold text-[16px] block sm:hidden text-[#31A246]"
                        >
                          Back
                        </span>
                        <span className="font-medium text-[16px] text-[#707070]">
                          Edit User
                        </span>
                        <div>
                          <span
                            onClick={closeEditUserDialogBox}
                            className="w-[14px] cursor-pointer hidden sm:block"
                          >
                            <CrossIcon className="goBackIcon cursor-pointer hover:bg-[#F5F5F5]" />
                          </span>
                        </div>
                      </div>
                    </ThemeDialogTitle>
                    <ThemeDialogContent>
                      <EditUserModalForm
                        closeEditUserDialogBox={closeEditUserDialogBox}
                        selectedItem={selectedItem}
                        editUserOpen={editUserOpen}
                        onSubmit={handleFormSubmit}
                      />
                    </ThemeDialogContent>
                  </ThemeDialog>

                  <ThemeDialog
                    open={deleteUserOpen}
                    scroll="paper"
                    keepMounted
                    // onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <ThemeDialogTitle>
                      <div className="flex justify-between rounded-[8px 8px 0px 0px]  items-center py-[12px] pl-[20px] pr-[28px] ">
                        <span
                          onClick={() => handleDeleteUserClose()}
                          className="font-bold text-[16px] block sm:hidden text-[#31A246]"
                        >
                          Back
                        </span>
                        <span className="font-medium text-[16px] text-[#707070]">
                          Delete User
                        </span>
                        <div>
                          <span
                            onClick={closeDeleteUserDialogBox}
                            className="w-[14px] cursor-pointer hidden sm:block"
                          >
                            <CrossIcon className="goBackIcon cursor-pointer hover:bg-[#F5F5F5]" />
                          </span>
                        </div>
                      </div>
                    </ThemeDialogTitle>
                    <ThemeDialogContent>
                      <DeleteUserModalDialog
                        closeDeleteUserDialogBox={closeDeleteUserDialogBox}
                        selectedItem={selectedItem}
                      />
                    </ThemeDialogContent>
                  </ThemeDialog>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default UserView;
