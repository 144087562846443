import React, { useState, useEffect, useRef } from "react";
import TopBar from "../components/TopBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useHistory } from "react-router-dom";
import { BaseUrl } from "../BaseURL/BaseUrl";
import "../css/ProductPageCLink.css";
import { ReactComponent as PdfIcon } from "../image/svg/AddProductErrorDialogIcon.svg";
import { ReactComponent as DownloadIcon } from "../image/svg/DownloadIcon.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { inputFocus, changeInput } from "../muiTheme/OnFilledBorderStyle";
import { ReactComponent as ArrowIcon } from "../image/svg/arrowIcon.svg";
import {
  ThemeTextField,
  ThemeButton,
  ThemeDialogContent,
  ThemeDialogTitle,
  ThemeDialog,
} from "../muiTheme/Theme";
import { ReactComponent as CrossIcon } from "../image/svg/BillCrossIcon.svg";
import TechnicalConfirmationModel from "../components/TechnicalConfirmationModel";
import { getProcessedProducts } from "../redux/Slices/GetProcessedProductsSlice";
import {
  ThemeDrawer,
  ThemeTransferDialog,
  StyledModal,
} from "../muiTheme/SingleProductTheme";
import ImagePreviewer from "../components/ImagePreviewer";
import { useDispatch, useSelector } from "react-redux";
import MessageConfirmationBox from "../components/MessageConfirmationBox";
import { updateProductUrl } from "../redux/Slices/UpdateProductURLSlice";
import { updateGoodsBill,selectAllGoodsBillsDone  } from "../redux/Slices/UpdateGoodsBillStatusSlice";
import { addEmission } from "../redux/Slices/AddEmissionSlice";
import jwt_decode from "jwt-decode";
import { set } from "react-hook-form";
import { setPopUpOpen } from "../redux/Slices/OnTechPopupSlice";
import { setLinkPopUpOpen } from "../redux/Slices/LinkConfirmationPopUpSlice";
import { setupdateData } from "../redux/Slices/UpdataDataSlice";
import { useCallback } from "react";
function ProductPageCLink() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);
  const [openEmission, setOpenEmission] = useState(false);
  const [dialogOpen, setdialogOpen] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [openDiv, setOpenDiv] = useState(false);
  const location = useLocation();
  const products = location.state;
  const [selectedImagePath, setSelectedImagePath] = useState("");
  const [productUrlUpdated, setProductUrlUpdated] = useState("");
  const [productId, setProductId] = useState("");
  const [productStatus, setproductStatus] = useState("");
  const [goodBillStatus, setGoodBillStatus] = useState("");
  const [productUrlConfirmed, setProductUrlConfirmed] = useState("");
  const [emissionNo, setEmissionNo] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState(null);
  const [popUpValue, setPopUpValue] = useState(0);
  // const productData = useSelector((state) => state.product.data);
  const token = JSON.parse(localStorage.getItem("TechnicalToken"));
  const decodedToken = jwt_decode(token);
  const uId = decodedToken._id;

  const allProductUrl = useSelector(
    (state) => state.updateproducturl.allProductUrlsDone
  );
  console.log(allProductUrl, "taskssssssss");

  const allDoneGoodsBill = useSelector(selectAllGoodsBillsDone);

  console.log(allProductUrl, "taskssssssss");
  console.log(allDoneGoodsBill, "allDoneGoodsBill");

  console.log(products._id, "productsid");
  console.log(uId, "uId");

  var status = products.status;

  // var allDoneGoodsBill = false;
  // if (Array.isArray(products.goodsBill)) {
  //   for (const item of products.goodsBill) {
  //     if (item.status !== "done") {
  //       allDoneGoodsBill = false;
  //       break;
  //     } else {
  //       allDoneGoodsBill = true; 
  //     }
  //   }
  // }
  // console.log(allDoneGoodsBill, "allDoneGoodsBill");

  // var allProductUrl = false;
  // if (Array.isArray(products.productUrl)) {
  //   for (const item of products.productUrl) {
  //     if (item.status !== "done") {
  //       allProductUrl = false;
  //       break;
  //     } else {
  //       allProductUrl = true; 
  //     }
  //   }
  // }
  // console.log(allProductUrl, "allProductUrl");

  // if (allDoneGoodsBill && allProductUrl) {
  //   status = "done";
  // }

  console.log(status, "status");

  // console.log(status, "final status");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenSecond = () => {
    setOpenSecond(true);
  };
  const handleCloseSecond = () => {
    setOpenSecond(false);
  };
  const handleOpenEmission = () => {
    setOpenEmission(true);
  };
  const handleCloseEmission = () => {
    setOpenEmission(false);
  };

  const handleClickSOneOpen = () => {
    setShow(true);
  };
  const handleClickSOneClose = () => {
    setShow(false);
  };

  const handleShowSecondOpen = () => {
    setShowSecond(true);
  };
  const handleShowSeconClose = () => {
    setShowSecond(false);
  };

  const handleRadioChange = (event, index) => {
    if (
      event.target.value === "doesntMatch" ||
      event.target.value === "correct"
    ) {
      setShowDiv(true);
      setSelectedIndex(index);
      setOpenDiv(false);
    } else if (
      event.target.value === "match" ||
      event.target.value === "inCorrect"
    ) {
      setOpenDiv(true);
      setSelectedIndex(index);
      setShowDiv(false);
    } else {
      setShowDiv(false);
      setSelectedIndex(0);
      setOpenDiv(false);
    }
  };

  const handleChange = (event) => {
    setProductUrlUpdated(event.target.value);
  };

  const handleChangeEmission = (event) => {
    setEmissionNo(event.target.value);
  };

  useEffect(() => {
    dispatch(getProcessedProducts());
  }, [dispatch]);

  const handleProductUrlUpdatedComply = () => {
    console.log(`link doesn't cpmply`);
  };

  const handleProductUrlUpdated = async (index) => {
    console.log(index, "Clicked");
    try {
      let id = products._id;
      let productUrlStatus = "done";
      let userId = uId;
      let res = await dispatch(updateProductUrl({ id, productUrlStatus, userId,index }));
      if (res.payload.success === true) {
        dispatch(getProcessedProducts());
        dispatch(setLinkPopUpOpen(true));
        dispatch(setupdateData(true));
        navigate("/my-tasks-products", {state: true});
      } 
    } catch (error) {
      console.log(error);
    }
  };


  const handleUpdateGoodsBill = async (index) => {
    console.log(index, "index of do match");
    try {
      setShow(false);
      let id = products._id;
      let userId = uId;
      let goodsBillStatus = "error";
      let res = await dispatch(
        updateGoodsBill({ id, goodsBillStatus, userId, index })
      );
      if (res.payload.success === true ) {
        dispatch(setPopUpOpen(true));
        navigate("/my-tasks-products");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(arrLen, "Length")

  const handleUpdateGoodsBillMatch = async (index) => {
    console.log(index, "index of match");
    try {
      setShowSecond(false);
      let id = products._id;
      let userId = uId;
      let goodsBillStatus = "done";
      let res = await dispatch(
        updateGoodsBill({ id, goodsBillStatus, userId, index})
      );
      if (res.payload.success === true) {
        dispatch(setPopUpOpen(true));
        navigate("/my-tasks-products");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddEmission = async () => {
    try {
      let userId = uId;
      let id = products._id;
      let emission = emissionNo;
      console.log(userId, id, emission, "Data");
      setOpenEmission(false);
      setEmissionNo("");
      if (emissionNo) {
        await dispatch(addEmission({ id, emission, userId }));
        navigate("/my-tasks-products");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(updateProductUrl());
  }, [handleProductUrlUpdated]);

  useEffect(() => {
    dispatch(updateGoodsBill());
  }, [handleUpdateGoodsBillMatch]);

  useEffect(()=>{
    dispatch(getProcessedProducts());
    console.log("called")
  },[handleProductUrlUpdated,handleUpdateGoodsBillMatch])

  return (
    <div>
      <div>
        <TopBar />
      </div>

      <div className=" px-[42px]  ">
        <div className="pt-[64px] sm:mt-[32px]">
          <div>
            <ArrowIcon
              className="goBackIcon cursor-pointer hover:bg-[#F5F5F5]"
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="w-[1200px] flex flex-column">
            <div className="w-[496px]">
              <div className="flex flex-col justify-left mt-[40px]">
                <span className="h-text">Product name</span>
                <p className="text-[#2c2c2c] mt-[12px]">
                  {products.productName}
                </p>
              </div>
              <div className="flex flex-col justify-left mt-[40px]">
                <span className="h-text">Product description</span>
                <p className="text-[#2c2c2c] mt-[12px]">
                  {products.productDescription}
                </p>
              </div>
              <div className="flex flex-col justify-left mt-[40px]">
                <span className="h-text">Photos</span>
                <div className="flex gap-[32px]">
                  {
                    // products.productPhoto &&
                    //   Array.isArray(products.productPhoto) &&
                    //   JSON.parse(products.productPhoto).length > 0 &&
                    JSON.parse(products.productPhoto).map((photo, index) => (
                      <div key={index}>
                        <img
                          src={`${BaseUrl}/public/productImages/${photo.path}`}
                          alt={products.productName}
                          className="image-box"
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
              {products.productUrl &&
              JSON.parse(products.productUrl).some(
                (Item) => Item.status !== "done"
              ) ? (
                <>
                  {JSON.parse(products.productUrl).map((item, index) => (
                      <div className="flex flex-col justify-left mt-[40px]">
                      <div className="flex flex-col justify-left mt-[40px] w-[421.96px]">
                        <span className="h-text">URL of the product</span>
                        <p className="text-[#2c2c2c] mt-[12px]">
                          Review the link customer provided if it's correct or
                          not and mark the radio-button below.
                        </p>
                      </div>

                      <div className="flex flex-col justify-left mt-[40px] w-[400px] h-[40px]">
                        <ThemeTextField
                          size="small"
                          id="productNameFocusClass"
                          // className="w-[150px] h-[40px]"
                          name="productName"
                          defaultValue={item.url}
                          onChange={handleChange}
                          // placeholder={JSON.parse(products.productUrl)[0].url}
                          onFocus={(event) => inputFocus(event)}
                          onBlur={(event) => changeInput(event)}
                        />
                      </div>
                      <div className="mt-[20px] ">
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event) => {
                              // console.log(index, "RadioIndex");
                              handleRadioChange(event, index);
                            }}
                          >
                            <FormControlLabel
                              value="correct"
                              control={<Radio style={{ color: "#57B353" }} />}
                              label="Link is correct"
                            />
                            <FormControlLabel
                              value="inCorrect"
                              control={<Radio style={{ color: "#57B353" }} />}
                              label="Link doesn't comply "
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      {showDiv && selectedIndex == index && (
                        <>
                          <MessageConfirmationBox
                            innerText={`After confirmation, product goes live. Action can't be 
                              undone, so make sure the link customer provided 
                              contain all required information.`}
                            onClick={handleClickOpen}
                          />
                          <ThemeTransferDialog
                            open={open}
                            scroll="paper"
                            keepMounted
                            // onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                          >
                            <ThemeDialogTitle>
                              <div className="flex justify-between rounded-[8px 8px 0px 0px]  items-center py-[12px] pl-[20px] pr-[28px] ">
                                <span
                                  onClick={() => handleClose()}
                                  className="font-bold text-[16px] block sm:hidden text-[#31A246]"
                                >
                                  Back
                                </span>
                                <span
                                  className="font-medium text-[16px] text-[#707070]"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Link Confirmation
                                </span>
                                <div>
                                  <span
                                    onClick={() => handleClose()}
                                    className="w-[14px] cursor-pointer hidden sm:block"
                                  >
                                    <CrossIcon
                                      className="goBackIcon cursor-pointer hover:bg-[#F5F5F5]"
                                      style={{
                                        width: "28px",
                                        height: "28px",
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </ThemeDialogTitle>
                            <ThemeDialogContent>
                              <TechnicalConfirmationModel
                                handleClose={handleClose}
                                open={open}
                                text={
                                  "Are you sure you checked the link properly?"
                                }
                                buttontext={"Confirm"}
                                onSendClick={() =>
                                  handleProductUrlUpdated(index)
                                }
                              />
                            </ThemeDialogContent>
                          </ThemeTransferDialog>
                        </>
                      )}

                      {openDiv && selectedIndex == index && (
                        <>
                          <MessageConfirmationBox
                            innerText={`After confirmation! product goes live. Action can't be 
                      undone, so make sure the link customer provided 
                      contain all required information.`}
                            onClick={handleOpenSecond}
                          />
                          <ThemeTransferDialog
                            open={openSecond}
                            scroll="paper"
                            keepMounted
                            // onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                          >
                            <ThemeDialogTitle>
                              <div className="flex justify-between rounded-[8px 8px 0px 0px]  items-center py-[12px] pl-[20px] pr-[28px] ">
                                <span
                                  onClick={() => handleCloseSecond()}
                                  className="font-bold text-[16px] block sm:hidden text-[#31A246]"
                                >
                                  Back
                                </span>
                                <span
                                  className="font-medium text-[16px] text-[#707070]"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Link Confirmation
                                </span>
                                <div>
                                  <span
                                    onClick={() => handleCloseSecond()}
                                    className="w-[14px] cursor-pointer hidden sm:block"
                                  >
                                    <CrossIcon
                                      className="goBackIcon cursor-pointer hover:bg-[#F5F5F5]"
                                      style={{
                                        width: "28px",
                                        height: "28px",
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </ThemeDialogTitle>
                            <ThemeDialogContent>
                              <TechnicalConfirmationModel
                                handleClose={handleCloseSecond}
                                open={open}
                                text={
                                  "Are you sure you checked the link properly?"
                                }
                                buttontext={"Confirm"}
                                onSendClick={() =>
                                  handleProductUrlUpdatedComply(index)
                                }
                              />
                            </ThemeDialogContent>
                          </ThemeTransferDialog>
                        </>
                      )}
                    </div>
                    
                   
                  ))}
                </>
              ) : (
                <div>
                  {JSON.parse(products?.goodsBill).map((photo, index) => (
                    <div>
                      <div className="flex flex-col justify-left mt-[40px]">
                        <span className="h-text">Bill of goods</span>
                        <p className="text-[#2c2c2c] mt-[12px]">
                          Review the bill of goods if it's correct or not and
                          mark the radio-button below.
                        </p>
                      </div>
                      <div className="flex flex-col justify-left mt-[40px]">
                        <div className="flex justify-between">
                          <div
                            className="flex gap-[8px] items-center cursor-pointer"
                            onClick={() => {
                              setSelectedImagePath(photo.path);
                              setdialogOpen(photo);
                            }}
                          >
                            <p>
                              <PdfIcon
                                style={{ width: "34px", height: "34px" }}
                              />
                            </p>
                            <p className="addProductErrorFileName">
                              {photo.path}
                            </p>
                            <p className="pdfSize">
                              {(photo.path.length / (1024 * 1024)).toFixed(2) +
                                " mb"}
                            </p>
                          </div>

                          <ThemeDrawer
                            anchor="right"
                            open={dialogOpen}
                            onClose={() => setdialogOpen(false)}
                          >
                            <ImagePreviewer
                              setOpen={setdialogOpen}
                              image={`${BaseUrl}/public/billsFiles/${selectedImagePath}`}
                            />
                          </ThemeDrawer>
                        </div>
                        <div className="mt-[28px]">
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              onChange={(event) => {
                                // console.log(index, "RadioIndex");
                                handleRadioChange(event, index);
                              }}
                            >
                              <FormControlLabel
                                value="match"
                                control={<Radio style={{ color: "#57B353" }} />}
                                label="Bill of goods is correct"
                              />
                              <FormControlLabel
                                value="doesntMatch"
                                control={<Radio style={{ color: "#57B353" }} />}
                                label="Bill of goods doesn't match"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>

                      {/* /////Bill of goods does not match model/// */}
                      {showDiv && selectedIndex == index && (
                        <>
                          <MessageConfirmationBox
                            innerText={`This bill of goods will be send to the customer back to replace it with a proper one. After confirmation, action can't be undone.`}
                            onClick={handleClickSOneOpen}
                          />
                          <ThemeTransferDialog
                            open={show}
                            scroll="paper"
                            keepMounted
                            // onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                          >
                            <ThemeDialogTitle>
                              <div className="flex justify-between rounded-[8px 8px 0px 0px]  items-center py-[12px] pl-[20px] pr-[28px] ">
                                <span
                                  onClick={() => handleClose()}
                                  className="font-bold text-[16px] block sm:hidden text-[#31A246]"
                                >
                                  Back
                                </span>
                                <span
                                  className="font-medium text-[16px] text-[#707070]"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Link Confirmation
                                </span>
                                <div>
                                  <span
                                    onClick={() => handleClickSOneClose()}
                                    className="w-[14px] cursor-pointer hidden sm:block"
                                  >
                                    <CrossIcon
                                      className="goBackIcon cursor-pointer hover:bg-[#F5F5F5]"
                                      style={{ width: "28px", height: "28px" }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </ThemeDialogTitle>
                            <ThemeDialogContent>
                              <TechnicalConfirmationModel
                                handleClose={handleClickSOneClose}
                                open={show}
                                text={
                                  "Are you sure you checked the link properly?"
                                }
                                buttontext={`Confirm`}
                                onSendClick={() => handleUpdateGoodsBill(index)}
                              />
                            </ThemeDialogContent>
                          </ThemeTransferDialog>
                        </>
                      )}

                      {/* /////Bill of goods match model/// */}
                      {openDiv && selectedIndex == index && (
                        <>
                          <MessageConfirmationBox
                            innerText={`This bill of goods will be send to the customer back to replace it with a proper one. After confirmation, action can't be undone.`}
                            onClick={handleShowSecondOpen}
                          />
                          <ThemeTransferDialog
                            open={showSecond}
                            scroll="paper"
                            keepMounted
                            // onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                          >
                            <ThemeDialogTitle>
                              <div className="flex justify-between rounded-[8px 8px 0px 0px]  items-center py-[12px] pl-[20px] pr-[28px] ">
                                <span
                                  onClick={() => handleShowSeconClose()}
                                  className="font-bold text-[16px] block sm:hidden text-[#31A246]"
                                >
                                  Back
                                </span>
                                <span
                                  className="font-medium text-[16px] text-[#707070]"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Link Confirmation
                                </span>
                                <div>
                                  <span
                                    onClick={() => handleShowSeconClose()}
                                    className="w-[14px] cursor-pointer hidden sm:block"
                                  >
                                    <CrossIcon
                                      className="goBackIcon cursor-pointer hover:bg-[#F5F5F5]"
                                      style={{ width: "28px", height: "28px" }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </ThemeDialogTitle>
                            <ThemeDialogContent>
                              <TechnicalConfirmationModel
                                handleClose={handleShowSeconClose}
                                open={showSecond}
                                text={
                                  "Are you sure you checked the link properly?"
                                }
                                buttontext={"Confirm"}
                                onSendClick={() =>
                                  handleUpdateGoodsBillMatch(index)
                                }
                              />
                            </ThemeDialogContent>
                          </ThemeTransferDialog>
                        </>
                      )}
                    </div>
                  ))}

                  {/* __________________________________________ */}
                  <div className=" my-[45px]">
                    <h1 className="heading">Put the emission number </h1>
                    <div className="flex justify-center items-center gap-[20px]  pt-[26px] ">
                      <ThemeTextField
                        size="small"
                        id="productNameFocusClass"
                        className="w-[150px] h-[40px]"
                        value={emissionNo}
                        onChange={handleChangeEmission}
                        name="productName"
                        placeholder="Put emission number"
                        onFocus={(event) => inputFocus(event)}
                        onBlur={(event) => changeInput(event)}
                      />
                      <ThemeButton
                        type="submit"
                        variant="contained"
                        className="w-[221px] h-[44px]"
                        onClick={handleOpenEmission}
                      >
                        Confirm
                      </ThemeButton>
                    </div>

                    {/* /////Emission number model/// */}

                    <ThemeTransferDialog
                      open={openEmission}
                      scroll="paper"
                      keepMounted
                      // onClose={handleClose}
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <ThemeDialogTitle>
                        <div className="flex justify-between rounded-[8px 8px 0px 0px]  items-center py-[12px] pl-[20px] pr-[28px] ">
                          <span
                            onClick={() => handleCloseEmission()}
                            className="font-bold text-[16px] block sm:hidden text-[#31A246]"
                          >
                            Back
                          </span>
                          <span
                            className="font-medium text-[16px] text-[#707070]"
                            style={{ fontWeight: "bold" }}
                          >
                            Link Confirmation
                          </span>
                          <div>
                            <span
                              onClick={() => handleCloseEmission()}
                              className="w-[14px] cursor-pointer hidden sm:block"
                            >
                              <CrossIcon
                                className="goBackIcon cursor-pointer hover:bg-[#F5F5F5]"
                                style={{ width: "28px", height: "28px" }}
                              />
                            </span>
                          </div>
                        </div>
                      </ThemeDialogTitle>
                      <ThemeDialogContent>
                        <TechnicalConfirmationModel
                          handleClose={handleCloseEmission}
                          open={openEmission}
                          text={"Are you sure you checked the link properly?"}
                          buttontext={"Confirm"}
                          onSendClick={handleAddEmission}
                        />
                      </ThemeDialogContent>
                    </ThemeTransferDialog>
                  </div>
                </div>
              )}
            </div>
            <div className="w-[706px]">
              <div className="flex flex-col justify-left mt-[40px]">
                <span className="h-text">Serial number</span>
                <p className="text-[#2c2c2c] mt-[12px]">
                  {products.serialNumber}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductPageCLink;
