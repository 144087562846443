import React, { useState } from "react";

import { ReactComponent as DownloadPhoto } from "../image/svg/AddProductErrorDialogIcon.svg";
import p1 from "../image/cardImg.png";
import p2 from "../image/DownloadPhotoArrow.png";
import { BaseUrl } from "../BaseURL/BaseUrl";
import { useSelector } from "react-redux";

const DownloadPhotoDialogCompo = (props) => {
  const [show, setshow] = useState(false);
  const thsDevice = navigator.platform;
  const [image, setimage] = useState();
  const [URL, SetUrl] = useState('');
  // const errorData = useSelector((state) => state.addAdminPhoto);
  // var newData = [];
  // if (errorData.dataError.length > 0) {
  //   newData = errorData.dataError[0].photos;
  //   console.log(newData, "download Datayy");
  // }
  // const newData = errorData.dataError.filter((item) => item.type == "download");
  const item = props.downloadPhoto;
  const mobileBlur = (e) => {
    const deviceName = thsDevice.split(" ");
    if (e.target.classList.contains("actCertImg")) {
      if (deviceName[0] == "Linux") {
        setshow(false);
      }
    }
  };

  const downloadPhoto = (data) => {
    const url = `${BaseUrl}/public/productImages/${data}`;
    SetUrl(url);
    var a = document.createElement("a");
    a.download = url;
    a.setAttribute('download', data);
    a.setAttribute('href', url);

    a.style.display = 'none';
    document.body.appendChild(a);
    // return false;
    a.click();
    document.body.removeChild(a);
  };
  return (
    <div>
      {/* {newData?.map((item,index) => ( */}
      <div>


        <div className="absolute">

          <img
            width={"138px"}
            height="114px"
            id="certificatePhoto"
            className="actCertImg pt-[19px] h-[114px]  pl-[18px]"
            onMouseEnter={() => setshow(true)}
            onMouseLeave={(e) => mobileBlur(e)}
            src={
              `${BaseUrl}/public/productImages/${item}`

            }
            alt=""
          />

        </div>

        <div onMouseLeave={() => setshow(false)} className="absolute">
          {show && (
            <div className="hoverDownload-img " >

              <img
                onClick={(e) => downloadPhoto(item)}
                className="cursor-pointer "
                src={p2}
                alt=""
              // onMouseLeave={() => setshow(false)}
              />
            </div>

            // </>
          )}
        </div>
      </div>
      {/* ))} */}
    </div>
  );
};

export default DownloadPhotoDialogCompo;
