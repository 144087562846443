import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL/BaseUrl";
import axios from "axios";

export const getProcessedProducts = createAsyncThunk(
  "product/getProcessedProducts",
  async () => {
    try {
      const token = JSON.parse(localStorage.getItem("TechnicalToken"));
      const res = await axios.get(`${BaseUrl}/api/product/getprocessproducts`, {
        headers: { Authorization: "Bearer " + token },
      });
      console.log("updated data, ",res.data);
      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getProcessedProductsSlice = createSlice({
  name: "processedProducts",
  initialState: {
    data: [],
    loading: false,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProcessedProducts.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getProcessedProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getProcessedProducts.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export default getProcessedProductsSlice.reducer;
