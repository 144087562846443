import { createSlice } from '@reduxjs/toolkit';

const OnTechPopupSlice = createSlice({
 name: 'ontechpopupslice',
 initialState: {
  popUpOpen: false, // Set an initial value for the `downloaded` property
 },
 reducers: {
  setPopUpOpen: (state, action) => {
   state.popUpOpen = action.payload; // Update the `downloaded` property in the state
  },
 },
});

export const { setPopUpOpen } = OnTechPopupSlice.actions;
export default OnTechPopupSlice.reducer;
