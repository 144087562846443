import React, { useState } from "react";
import TopBarSimple from "../components/TopBarSimple";
import "../css/font.css";
import { Box, Button, IconButton, InputAdornment } from "@mui/material";
import { ReactComponent as EyeIcon } from "../image/svg/closeEyeIcon.svg";
import { ReactComponent as OpenEyeIcon } from "../image/svg/openEyeIcon.svg";
import TextField from "@mui/material/TextField";
import { Link, useHistory } from "react-router-dom";
import {
  textFieldStyle,
  selectField,
  muiButton,
  boxStyleforlogin,
} from "../css/style";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import {
  ThemeTextField,
  ThemeSelect,
  ThemeButton,
  passwordFieldStyle,
} from "../muiTheme/Theme";
import {
  inputFocus,
  changeInput,
  passwordFocus,
  passwordBlur,
} from "../muiTheme/OnFilledBorderStyle";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addDefaultValue } from "../redux/Slices/RestPasswordDefultValueSlice";
import { useSelector } from "react-redux";
import { adminLogin } from "../redux/Slices/LoginAdminSlice";


const schema = yup.object().shape({
  email: yup.string().required("Name is required"),
  password: yup
    .string()
    .required("Code is required")
    .min(6, "Enter at least 6 characters"),
});

const StaffLogin = () => {
  const navigate = useNavigate();
  const [age, setAge] = React.useState("");
  const [clickKValue, setclickkValue] = useState(false);

  const [passwordError, setPasswordError] = useState(null);
  const [errorType, seterrorType] = useState(null);
  const [emailError, setemailError] = useState(null);

  const data = useSelector((state) => state.default.value);
  const [focusing, setFocusing] = useState(true);
  const [email, setemail] = useState();
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleCClickShowaccessCode = () => {
    setclickkValue(!clickKValue);
  };
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });



  const submitForm = (data) => {
    dispatch(adminLogin(data))
      .unwrap()
      .then((res) => {
        if (res?.success) {
          if (res.user.userType === "Admin") {
            localStorage.setItem("AdminToken", JSON.stringify(res.token));
            navigate("/admin-panel");
          } else if (res.user.userType === "Technical") {
            localStorage.setItem("TechnicalToken", JSON.stringify(res.token));
            navigate("/my-tasks-products");
          } else if (res.user.userType === "Designer") {
            localStorage.setItem("userId", JSON.stringify(res.user._id));
            localStorage.setItem("DesignerToken", JSON.stringify(res.token));
            navigate("/my-tasks");
          }
          // localStorage.setItem("user", JSON.stringify(res.user.userName));
          // localStorage.setItem("userId", JSON.stringify(res.user._id));
          // localStorage.setItem("companyId", JSON.stringify(res.user.companyId));
        }
        if (!res?.success) {
          console.log(res, "res");
          seterrorType(res.type);
          document.getElementById('signInAccessCode').parentElement.classList.remove('inputErrorShown');
          document.getElementById('signInName').parentElement.classList.remove('inputErrorShown');
          if (res.type == "password") {
            setPasswordError(res.message);
            document.getElementById('signInAccessCode').parentElement.classList.add('inputErrorShown');
          }
          if (res.type == 'email') {
            setemailError(res.message);

          }
          document.getElementsByTagName('body')[0].click();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const text = "Don't have an account?";
  const join = "Join";


  const valuee = watch("accessCode", false);
  console.log(valuee, "valueeeeeeee");

  const forgetCodeClick = () => {
    dispatch(addDefaultValue(email));
    navigate("/forgotcode");
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   navigate("/userPanel");
  // }


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="setProjectBg">
      <TopBarSimple />

      <form onSubmit={handleSubmit(submitForm)}>
        <div className="mainDiv">
          <div className=" formBox !mt-[221px]">
            <div className="formHeader  ">
              <h5 className=" headline">Staff dashboard</h5>
            </div>
            <div className="inputsTextBoxesDiv">
              <div className="singleTextBoxDiv">
                <p className="text_label">Email</p>
                <ThemeTextField
                  id="signInName"
                  autoFocus={true}
                  name="email"
                  type={"text"}
                  size="small"
                  fullWidth
                  defaultValue={data}
                  // sx={textFieldStyle}
                  placeholder="Enter your email"

                  {...register("email")} W
                  onBlur={(event) => changeInput(event)}
                  onFocus={(event) => inputFocus(event)}
                  error={errors.email}
                // sx={{ ${errors.email}? backgroundColor: "red" }}
                />
                {errors.name ? (
                  <p className="error_messge">{errors.email?.message}</p>
                ) : (
                  <p className="error_messge">
                    {errorType == "email" && emailError}
                  </p>

                )}
              </div>

              <div className="singleTextBoxDiv">
                <div className="text_label">
                  <p>Access code</p>
                  <p
                    onClick={forgetCodeClick}
                    className="textButton cursor-pointer text-[#31A246] hover:text-[#266E20] hover:underline underline-offset-[2px]"
                  >
                    Forgot Access Code?
                  </p>
                </div>
                <ThemeTextField
                  id="signInAccessCode"
                  name="password"
                  type={!clickKValue ? "password" : "text"}
                  size="small"
                  fullWidth
                  sx={valuee && passwordFieldStyle}
                  placeholder="At least 6 characters"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          aria-label="toggle accessCode visibility"
                          onClick={handleCClickShowaccessCode}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {valuee &&
                            (!clickKValue ? <EyeIcon /> : <OpenEyeIcon />)}
                          {/* <Visibility /> */}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...register("password")}
                  onFocus={(event) => passwordFocus(event, 'a')}
                  onBlur={(event) => passwordBlur(event)}
                  error={errors.password}
                />
                {errors.password ? (

                  <p className="w-full error_messge">
                    {errors.password?.message}
                  </p>
                ) : (
                  <p className="error_messge">
                    {errorType == "password" && passwordError}
                  </p>
                )}
              </div>
            </div>
            <div className="w-full mt-[20px]">
              <ThemeButton type="submit" fullWidth variant="contained">
                Sign in
              </ThemeButton>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default StaffLogin;
