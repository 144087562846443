import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const AdminPubRoute = () => {
 const token = JSON.parse(localStorage.getItem("AdminToken"));
 if (!token) {
  const items = JSON.parse(token);
  return <Navigate to={"/Staff-Login"} />;
 }
 return <Outlet />;
};

export default AdminPubRoute;