import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseUrl } from "../../BaseURL/BaseUrl";

export const addEmission = createAsyncThunk(
  "product/addEmission",
  async ({ id, emission, userId }) => {
    try {
      const token = JSON.parse(localStorage.getItem("TechnicalToken"));
      const response = await axios.post(
        `${BaseUrl}/api/product/addemission`,
        {
          id,
          emission,
          userId,
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const addEmissionSlice = createSlice({
  name: "product",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addEmission.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addEmission.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(addEmission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default addEmissionSlice.reducer;
