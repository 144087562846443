import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseUrl } from "../../BaseURL/BaseUrl";

export const updateGoodsBill = createAsyncThunk(
  "product/updateGoodsBill",
  async ({ id, goodsBillStatus, userId, index}) => {
    console.log("pressed");
    try {
      const token = JSON.parse(localStorage.getItem("TechnicalToken"));
      let fd = new FormData();
      fd.append("id", id);
      fd.append("goodsBillStatus", goodsBillStatus);
      fd.append("index", index);
      fd.append("userId", userId);
      const response = await axios.post(
        `${BaseUrl}/api/product/updategoodsbill`,
        fd,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      console.log(response.data.success, "RESP");
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const productGoodsSlice = createSlice({
  name: "updateproductgoodsbill",
  initialState: {
    data: [],
    loading: false,
    error: null,
    allGoodsBillsDone: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateGoodsBill.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateGoodsBill.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.updatedProduct;
        state.allGoodsBillsDone = action.payload.allGoodsBillsDone;
      })
      .addCase(updateGoodsBill.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectAllGoodsBillsDone = (state) => state.updateproductgoodsbill.allGoodsBillsDone;


export default productGoodsSlice.reducer;
