import React from 'react';
import { ReactComponent as RightIcon } from "../image/svg/RightIcon.svg";
import { ReactComponent as LeftIcon } from "../image/svg/LeftIcon.svg";
import UserPaginationContext from '../useContext/UserPaginationContext';
import { useContext } from 'react';

function PaginationAdminPanel() {
 const props = useContext(UserPaginationContext);
 return (
  <div className="flex justify-between  h-10 bg-gray-100 border border-gray-200 rounded-b-lg">
   <div className="flex justify-center items-center ml-4">
    <p className="text-sm text-center font-medium text-gray-900">Rows per Page : {props.pageCount}</p>
   </div>
   <div className="flex justify-center items-center mr-4" >
    <div className="flex mr-2">
     <p className="text-sm font-medium text-gray-900">{props.currentPage}-{props.pageCount} of {props.pageCount}</p>
    </div>
    <div className="flex ml-2" onClick={props.currentPage !== 1 ? props.handlePrev : null} >
     <LeftIcon />
    </div>
    <div className="flex" onClick={props.currentPage === props.pageCount ? null : props.handleNext} >
     <RightIcon />
    </div>

   </div>
  </div>
 );
}

export default PaginationAdminPanel;