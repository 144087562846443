import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL/BaseUrl";
const axios = require("axios");

export const getUserTask = createAsyncThunk("admin/getUserTask", async () => {
  try {
    const res = await axios.get(`${BaseUrl}/api/admin/getusertask`);

    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const getUserTaskSlice = createSlice({
  name: "userTasks",
  initialState: {
    tasks: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getUserTask.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getUserTask.fulfilled]: (state, action) => {
      state.loading = false;
      state.tasks = action.payload.tasks;
    },
    [getUserTask.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export default getUserTaskSlice.reducer;
