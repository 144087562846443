import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL/BaseUrl";
const axios = require("axios");

export const getAllUsersAdmin = createAsyncThunk(
 "admin/getusers",
 async () => {
  try {
   // const token = JSON.parse(localStorage.getItem("token"));
   // `${BaseUrl}/api/product/getallproduct/${companyId}`,
   // const companyId = JSON.parse(localStorage.getItem('companyId'));
   const res = await axios.get(
    `${BaseUrl}/api/admin/getusers`

    // {
    //  headers: { Authorization: "Bearer " + token },
    // }
   );
   return res.data;
  } catch (error) {
   console.log(error);
  }
 }
);
export const getAllUsersAdminSlice = createSlice({
 name: "allUsers",
 initialState: {
  data: [],
  loading: false,
  error: false,
 },
 reducers: {},
 extraReducers: {
  [getAllUsersAdmin.pending]: (state) => {
   state.loading = true;
   state.error = false;
  },
  [getAllUsersAdmin.fulfilled]: (state, action) => {
   state.loading = false;
   state.data = action.payload;
  },
  [getAllUsersAdmin.rejected]: (state) => {
   state.loading = false;
   state.error = true;
  },
 },
});

export default getAllUsersAdminSlice.reducer;
