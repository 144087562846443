import React, { useEffect, useState } from 'react';

const UnselectIcon = () => (
    <svg
        className='fill-current text-gray-600'
        viewBox="0 0 1024 1024"
        fill="currentColor"
        height="1em"
        width="1em"
    >
        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
    </svg>
);

const CustomButton = ({ name, options, selectedItem, ...props }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [error, setError] = useState("");


    useEffect(() => {

        if (selectedItem?.userType) {
            setSelectedOption(selectedItem?.userType);
        } else if (!selectedItem?.userType) {
            setSelectedOption(null);
        }
    }, [selectedItem?.userType]);


    const handleOptionClick = (option) => {
        if (selectedOption === option) {
            setSelectedOption(null);
        } else {
            setSelectedOption(option);
        }

        // if (selectedItem?.userType === option) {
        //     setSelectedOption(selectedItem?.userType);
        // }
    };

    const handleOptionUnselect = () => {
        setSelectedOption(null);
    };

    props.onDataFromChild(selectedOption);

    return (
        <div className="flex space-x-4 items-center">
            {options.map((option) => (
                <div
                    key={option}
                    className={`flex items-center justify-center px-2 py-1 border rounded-full ${selectedOption === option ? "border-gray-500" : "border-gray-300"
                        }`}
                    onClick={() => handleOptionClick(option)}
                    style={{ cursor: "pointer" }}
                >
                    <span>{option}</span>
                    {selectedOption === option && (
                        <button
                            type="button"
                            className=" flex items-center ml-2 justify-center w-4 h-6 rounded-full border-gray-500"
                            onClick={() => handleOptionUnselect(option)}
                            style={{ cursor: "pointer" }}
                        >
                            <UnselectIcon />
                        </button>
                    )}
                </div>
            ))}
            {error && <span className="text-red-500">{error}</span>}
        </div>
    );
};

export default CustomButton;