import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL/BaseUrl";
const axios = require("axios");

export const GetShowPhotosWithReviews = createAsyncThunk(
 "admin/showphotoswithreviews",
 async () => {
  try {
   var productPhotos = [];
   const res = await axios.get(
    `${BaseUrl}/api/admin/showphotoswithreviews`
   );

   res.data.photosWithReviews?.map((item) =>
    productPhotos.push({ proId: item._id, photos: JSON.parse(item.productPhoto) }));
   return productPhotos;
  } catch (error) {
   console.log(error);
  }
 }
);
export const GetShowPhotosWithReviewsSlice = createSlice({
 name: "allPhotosWithReviews",
 initialState: {
  data: [],
  loading: false,
  error: false,
 },
 reducers: {},
 extraReducers: {
  [GetShowPhotosWithReviews.pending]: (state) => {
   state.loading = true;
   state.error = false;
  },
  [GetShowPhotosWithReviews.fulfilled]: (state, action) => {
   state.loading = false;
   state.data = action.payload;
  },
  [GetShowPhotosWithReviews.rejected]: (state) => {
   state.loading = false;
   state.error = true;
  },
 },
});

export default GetShowPhotosWithReviewsSlice.reducer;
