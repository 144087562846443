import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseURL/BaseUrl";
const axios = require("axios");

export const editUser = createAsyncThunk("admin/updateadmin", async (data) => {
 console.log(data, "DATA");
 try {
  let fd = new FormData();
  fd.append("id", data.id);
  fd.append("email", data.email);
  fd.append("started", data.started);
  fd.append("userName", data.userName);
  fd.append("userType", data.userType);
  const res = await axios.post(
   `${BaseUrl}/api/admin/updateadmin`,
   fd
  );

  return res.data;
 } catch (error) {
  console.log(error);
 }
});

export const editUserAdminSlice = createSlice({
 name: "editUser",
 initialState: {
  data: [],
  loading: false,
  error: false,
 },
 reducers: {},
 extraReducers: {
  [editUser.pending]: (state) => {
   state.loading = true;
   state.error = false;
  },
  [editUser.fulfilled]: (state, action) => {
   state.loading = false;
   state.data = action.payload;
  },
  [editUser.rejected]: (state) => {
   state.loading = false;
   state.error = true;
  },
 },
});

export default editUserAdminSlice.reducer;